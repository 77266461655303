
.homepage{
  background-color: #000000;
}
#logo2{
  width: 250px;

}
.lines{
  font-size: 18px !important;
  padding-right: 13px;
}

/*popup*/

.free-consultation {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: linear-gradient(to right, #a610ed,#8201e8);
    /* padding: 60px 10px; */
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
   
    z-index: 9999;
    display: flex;
    

}


.BtnSection{
    background: transparent;
    border: none;
    writing-mode: vertical-rl;
    font-weight: bold;
    padding: 40px 8px;
    font-size: 1.1rem;
    color: #fff;   
    font-family: 'Aileron-Bold';
}

.free-consultation:hover{
    background: linear-gradient(to right, #b12eee,#720ec0);
}
.popup{
    width: 100%;
    max-width:500px;
    display: grid !important;
    place-items: center !important;
    display: none !important;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease, visibility 0.5s ease;
    animation-duration: 0.5s;
    animation-fill-mode: both;
    padding: 12px;
}
.show .popup {
    display: block !important;
    opacity: 1;
    visibility: visible;
    animation-name: scaleIn;
  }
  @keyframes scaleIn {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  .show .BtnSection {
    display: none !important;
  }

.cancel{ 
    width: 100%;
    padding-bottom: 15px;
}
.hide_arrow{   
    background-color: transparent;
    border: none;
    /* padding:8px 30px; */
}
.hide_arrow img{
  width: 40px;
  position: absolute;
  /* top: 40px; */
}

.headp{
    width: 100%;
    display: grid !important;
    place-items: center !important;
    text-align: center;


}
.headp h6{

    font-weight: 600;
    font-size: 18px;
    font-family: 'Aileron-Bold';
}
.headp p{
    font-weight: lighter;
    font-size: 14px;
    color: #ffffff;
    max-width: 300px;
    font-family: 'Aileron-UltraLight';
 
}





/* .col-md-4{
    width: 100% !important;
    
} */
.freeConsultation-form {
    margin-left: auto;
    width: 100% !important;
    max-width: 500px;
  }
  
  .popupform{
   
    width: 100% !important; 
    margin-right: 6%;
    padding: 0 10px;
    border-radius: 30px;
  
  }
  .popupform h6{
    text-align: center;
    color: #fff;
    padding: 8px 0;
  }
  .popupbox{
    position: relative;
    width: 100% !important;
    margin: 10px 0;
    display: flex;
    align-items: center;
    border: 1px solid #b4b0b0;
  }
  .popupbox img{
    margin-right: 10px;
  }
  
  .popupbox input{
    width: 100%;
    padding: 8px 30px;
    border: none !important;
    background-color: transparent;
    color: #fff !important ;
 
    outline: none;
    flex: 1;
  }
.flagp p{
  font-family: 'Aileron-UltraLight';
}


  .popupbox span{
    position: absolute;
    left: 0;
    padding: 12px 30px;
    color: #ffffff;
    text-transform: uppercase;
    pointer-events: none;
    font-size: 13px;
    transition: 0.5s;
    font-family: 'Aileron-UltraLight';
  }
/* PhoneInputCustom.css */
.phone-input-custom .form-control {
  outline: none !important;
  box-shadow: none !important;;
  background-color: transparent;
  color: white;
  font-family: 'Aileron-UltraLight';
}

.phone-input-custom .flag-dropdown {
  font-family: 'Aileron-UltraLight';
  background-color: transparent;
  color: white;
}

.phone-input-custom .country-list {
   font-family: 'Aileron-UltraLight';
  background-color: #a00dec;
  color: white;
}

.phone-input-custom .country {
 
  background-color: #a00dec;
  color: white;
}

.phone-input-custom .country:hover {

  background-color: #a00dec !important;
  color: white !important; 
}

  .popupbox input:valid ~ span,
  .popupbox input:focus ~ span
  {
    color: #f1f1f1;
    transform: translateX(20px) translatey(-13px);
    font-size: 0.6em;
    padding: 0 10px;
    font-weight: 500;
  }


  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button{
    -webkit-appearance: none;
  }
  


  .flagp{
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-right: 1px solid #ffff;
  }
  .flagp p{
    color: #fff;
    padding-top: 14px;
  }
  .sent-btn{
    width: 100%;
    display: flex;
    justify-content: right;
  }
  .sentBtn{
    background: transparent;
    border: none;
    outline: none;
  }
  .sent-btn img{
    width: 30px;
    cursor: pointer;
  }
  .query{
    display: flex;
    align-items: start;
    padding-top: 25px;
  }
  .text-areap{
    height: 100px;
  }



/* top-header */        
.top-header {
    width: 100vw;
    padding: 15px 40px 0; 
    position: absolute;
    background: transparent;  
    z-index: 9999;
  
}



.sidebar li a:hover{
    color: #a00dec;
  }

.fa-bars {
    color: #fff;
    font-size: 40px;
    cursor: pointer;
}

.container-fluid {
    width: 100%;
    padding: 0;
}

.banner {
    position: relative;
    top: 0;
    width: 100%;
    height: 100vh;
    /* background: url('../../../public/assets/images/bannerms.jpg') center/cover no-repeat; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #000000;
    
    /* overflow: hidden;  */
}
.bnnrImg{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 1240px;
}
.bnnrImg img{
  width: 100%;
}

.content {
    position: relative;
    z-index: 1;
    text-align: center;
}

.content h1 {
    font-size: 4.5rem;
    font-weight: 500;
    text-align: left;
    font-family: 'BebasNeue-Regular', sans-serif;
    line-height: 58px;
    margin-left: 5%;
    
}

.content h1 #digitamarket {
    color: #a00dec;
}

.sidebar {
  position: fixed;
  left: 70px;
  top: 50%;
  transform: translateY(-50%);
  list-style: none;
  padding: 0;
  margin: 0;
  border-left: 3px solid #fff;
   padding: 0 20px;
  /*margin-left: 5%; */
  z-index: 9999;
}

.sidebar li {
  margin-bottom: 10px;
}
.sidebar li a {
  color: #fff;
  text-decoration: none;
  font-size: 1.2rem;
  font-family: 'Aileron-Bold';
}
.sidebar li a:hover {
  color: #a00dec;
  font-weight: 600;
}

.btn-close{
  display: none;
}
.close-btn{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right !important;
}
.btn-close{
  outline: none !important;
  box-shadow: none !important;
  width: 30px !important;
  background-color: #fff !important;
  padding:8px 5px !important;
}



@media (min-width:200px) and (max-width:450px){

  
#logo2{
  width: 210px;
}

  
    .content h1 {
        font-size: 15vmin;
        font-weight: 500;
        text-align: left;
        font-family: 'Bebas Neue', sans-serif;
        line-height: 60px;
        margin-left: 0;
    }


    .swiper-slide h1{
        font-size: 2rem;
    }
}





@media (min-width:200px) and (max-width:999px){

    /*top and main*/

    .top-header .navbar a img{
        width: 210px;
        
     }
    
  
   
  

}


/* Responsive Design */

@media screen and (min-width: 1025px) and (max-width: 1366px) {
  .bnnrImg{
    width: 860px;
  }
}
@media (max-width: 1200px) {
    .content h1 {
        font-size: 3rem;
        line-height: 48px;
        margin-left: 3%;
    }
    .banner {
        background-size: contain;
    }
}

@media (max-width: 1000px) {
 .banner{
    position: relative;
    top: 0;
    width: 100%;
    height: 100vh;
    /* background: url('../../../public/assets/images/banner.jpg') center/cover no-repeat; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-size: contain;
 }
}
@media screen and (max-width: 1024px) {
  .bnnrImg{
    width: 760px;
  }
}

@media screen and (max-width:999px){
  .sidebar {
    position: fixed;
    width: 100% !important;
    height: 100vh;
    top: -1000px;
    left: 0;
    right: 0 !important;
    float: right;
    /* transform: translateY(-50%); */
    list-style: none;
    border-left: none;
    padding: 25px ;
    background-color: #a00dec;
    
    z-index: 9999;
    text-align: center;
    /* margin-top: 30px; */
    
}
.sidebar.active{
    top: 50%;
    transition: 0.6s;
}

.sidebar li {
    margin-bottom: 0px;
    padding: 15px 0;

    z-index: 1;
}
.sidebar li a {
    color: #fff;
    text-decoration: none;
    font-size: 1.5rem;
    font-family: 'Aileron', sans-serif;
    /* display: none; */
    
}
.sidebar li:hover{
   background-color: #fff;
   text-align: center;
   border-radius: 20px;
   
}
.sidebar li a:hover{
    color: #a00dec;
}
.btn-close{
    display: block;
    margin-top: 40px;
   
  }
}
@media (max-width: 768px) {
    .banner{
        position: relative;
        top: 0;
        width: 100%;
        height: 100vh;
      
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        background-size: contain;
     }
     .bnnrImg{
      width: 100%;
      height: 100%;
     }
     .bnnrImg::after{
      content: "";
      display: block;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(91deg, rgb(8 8 8) 0%, rgb(0 0 0 / 6%) 70%);
      position: absolute;
     }
     .bnnrImg img{
      width: 100%;
      height: 100%;
      object-fit: cover;
     }
    .top-header {
        padding: 10px 20px;
        
    }

    .fa-bars {
        font-size: 30px;
    }

}
@media screen and (max-width:600px) {
  .banner{
    position: relative;
    top: 0;
    width: 100%;
    height: 100vh;
    /* background: url('../../../public/assets/images/banner2.jpg') center/cover no-repeat; */
    display: flex;
    align-items: center;
    justify-content: start;
    color: white;
    background-size: contain;
    padding-left: 24px;
 }
 .bnnrImg{
  width: 100%;
  height: 100%;
 }
 .bnnrImg::after{
  content: "";
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(91deg, rgb(8 8 8) 0%, rgb(0 0 0 / 36%) 40%);
  position: absolute;
 }
 .bnnrImg img{
  width: 100%;
  height: 100%;
  object-fit: cover;
 }
}
@media (max-width: 480px) {
    .banner{
        position: relative;
        top: 0;
        width: 100%;
        height: 100vh;
        /* background: url('../../../public/assets/images/banner2.jpg') center/cover no-repeat; */
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        background-size: contain;
     }
    .content h1 {
        font-size: 2rem;
        line-height: 30px;
        margin-left: -47%;
    }


    
}





  


  
