
.section3{
    width: 100%;
  
    margin-top: 6%;
}
.banner3{
    top: 0;
    width: 100%;
    height: 40vh;
    background-image: url("../../../public/assets/images/contact-cover.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #2c2c3a;
}

.content3 {
    text-align: center;
    margin-left: 8%;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40vh;
    /* margin-top: 6%; */
    border-bottom: 1px solid #2c2c3a;
}
.content3 h1 {
    font-size: 3.5rem;
    font-weight: 500;
    text-align: left;
    font-family: 'BebasNeue-Regular';
    line-height: 58px;
    color: #ffffff;
}
.content3 h1 span{
    font-size: 2.5rem;
}

.bar3{
  width: 650px;
  height: 8px;
  background-color: #a50fed;
  bottom: 0;
  position: absolute;
}

@media (min-width:200px) and (max-width:999px){
    
    .content3 {
        position: relative;
        
        text-align: center;
        margin-left: 8%;
        display: flex;
        align-items: center;
        width: 100%;
        height: 40vh;
        margin-top: 6%;
    }
    .content3 h1 {
        font-size: 3.5rem;
        font-weight: 500;
        text-align: left;
        /* font-family: 'Bebas Neue', sans-serif; */
        line-height: 58px;
        color: #ffffff;
    }

    .section3{
        width: 100%;

        margin-top: 6%;
    }
    .banner3{
        top: 0;
        width: 100%;
        height: 40vh;
        background-image: url("../../../public/assets/images/contact-cover.jpg");
        background-position: right;
    }
}

@media (min-width:200px) and (max-width:580px){
    .content3 h1 {
        font-size: 28px;
        font-weight: 500;
        text-align: left;
        /* font-family: 'Bebas Neue', sans-serif; */
        line-height: 40px;
        color: #ffffff;
    }
    .content3 h1 span{
        font-size: 28px;
    }
    .banner3{
        top: 0;
        width: 100%;
        height: 40vh;
        background-image: url("../../../public/assets/images/contact-cover.jpg");
        background-position: right;
        background-size: cover;
        background-repeat: no-repeat;
        display: block;
        align-items: center;
        position: relative;
    }

}