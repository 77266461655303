.features-are2{
  width: 100%;
}
#list-group2{
  margin: 30px 0 !important;
padding: 0 !important;
}
.list-group-item{
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  gap: 30px;
}
.list-group-item img{
  width: 70px;
}
#desc{
  padding-top: 15px;
}
#desc h4{
  color: #fff;
  font-size: 26px;
  /* font-weight: 400; */
  font-family: 'Aileron-Regular';
}
#desc p{
  /* font-weight: 300; */
  font-family: 'Aileron-Regular';
}

.future2{
  width: 100%;
}
.future2 h5{
  color: #a50fed;
  font-weight: 700;
  font-size: 26px;
  font-family: 'Aileron-Bold';
}
.future2 p{
  font-size: 16px;
  width: 100%;
  /* max-width: 1000px; */
  font-family: 'Aileron-Regular';
  color: #fff;
  text-align: justify;
  /* font-weight: 300 !important; */
}

@media (max-width: 580px) {
  .features-are{
    width: 100%;
    padding: 30px !important;
  }
  .list-group-item{
    display: block !important;
    padding: 10px 20px;
  }
}