
.solutions7{
    /* margin: 40px 0; */
    margin-top: 30px;
  }
  .solution-container h5{
    color: #a50fed;
    font-weight: 500;
    font-family: 'Aileron-Bold';
  }
  .solution-container p{
    width: 100%;
    color: #fff;
    text-align: justify;
    font-weight: 400 !important;
    font-family: 'Aileron-Light';
  }
  .gy-5{
    /* width: 100% !important; */
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    margin-top: 2% !important;
  }
  .digital-solutions{
    width: 600px !important;
    text-align: left;
    padding: 0 !important;
    margin: 8px 0 !important;
  }
  .image7 img{
    width: 180px;
  }
  .solu-content{
    display: flex !important;
   
    gap: 20px;
    border: 1px solid #3e3e3e;
    padding: 0 !important;
    margin: 0 !important;
    background-color: #000;
  }
  .main-solutions{
    color: #fff;
    width: 100%;
     padding-top: 10px;
  }
  .main-solutions h6{
    font-weight: 600;
    font-size: 24px;
    font-family: 'Aileron-Bold';
  }
  .main-solutions p{
    font-weight: 100;
    font-size: 17px;
    font-family: 'Aileron-Light';
  }
  .arrow7{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .arrow-btn7{
    background: transparent;
    border: none;
    outline: none;
    padding-right: 15px;
  }
  .arrow-btn7 img{
    width: 60px;
  }
  
  @media (min-width:200px) and (max-width:1400px){
    .digital-solutions{
        width: 100% !important;
        text-align: left;
        padding: 0 !important;
      
      }
      .main-solutions{
        color: #fff;
        width: 100%;
        max-width: 100%;
      }
      
  }

  @media (min-width:200px) and (max-width:470px){
    .digital-solutions{
        width: 100% !important;
        text-align: left;
        padding: 0 !important;
      
      }
      .main-solutions{
        color: #fff;
        width: 100%;
        max-width: 100%;
        margin-top: 15px;
      }
    .image7 img{
      width: 100%;
    }
    
      .solu-content{
        display: block !important;
        align-items: center !important;
        gap: 20px;
        border: 1px solid #3e3e3e;
        padding: 0 !important;
        margin: 0 !important;
      }

      .main-solutions h6{
        font-weight: 600;
        font-size: 22px;
        margin-left: 6px;
      }
      .main-solutions p{
        font-weight: 300;
        font-size: 14.3px;
        margin-left: 6px;
      }
  }