.video-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    background-color: #000000 !important;
  }
  
  .video-container {
    width: 100%;
    max-width: 1200px;
    text-align: center;
    color: #fff;
  }
  
  .video-container h6 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #fff;
    font-family: 'Aileron-Bold';
  }
  
  .video {
    position: relative;
    width: 100%;
  }
  
  video {
    width: 100%;
    height: auto;
    border: none;
    outline: none;
    display: block;
  }
  

  #bi-pause-circle{
    width: 100px;
    color: black;
  }
 
  @media (max-width: 768px) {
    .video-container h6 {
      font-size: 1.2rem;
    }

    
  #bi-pause-circle{
    width: 70px;
    color: black;
  }
  .zig-img img {
    width: 250px;
}
  }
  

  
   .video-play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: none;
    border: none;
    cursor: pointer;
    color: #fff;
    font-size: 3rem;
  }
   
.video-section{
  width: 100%;
  background-color: #000;
  padding: 20px;margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-container{
  display: flex;
  align-items: center;
  gap: 10px;
}
.video-container h6{
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  font-family: 'Aileron';
  color: #fff;
  margin-left: 0;
}

@media(min-width:450px) and (max-width:700px){
  .video-section{
    width: 100%;
    background-color: #000;
    padding: 20px;margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.video-container{
    display: flex;
    align-items: center;
    gap: 10px;
}
.video-container h6{
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    font-family: 'Aileron';
    color: #fff;
    margin-left: 0;
}
}

@media(min-width:200px) and (max-width:700px){
  
.video-section{
  width: 100%;
  background-color: #000;
  padding: 20px;margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
}

.zig-img{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
  margin-top: 40px;
}
.zig-img img{
  width: 300px;
}

@media (max-width: 480px) {
  .video-container h6 {
    font-size: 1rem;
  }
  #bi-pause-circle{
    width: 50px;
    color: black;
  }
  .zig-img img {
    width: 250px;
}

}