
.servicesSec{
    width: 100%;
    display: grid;
    place-items: center;
    margin: 80px 0;
  }
  .row_section{
    width: 100%; 
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .col-service{
    display: flex;
    align-items: center !important;
    gap: 15px;
    margin: 20px 15px;
  }
  .col-service img{
    width: 50px;
  }
  .col3-1{
    max-width: 300px;
  }.col3-1 h4{
    color: #a510ed;
    font-weight: 300;
    font-family: 'Aileron-UltraLight';
  }.col3-1 p{
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    word-spacing: -1.1px;
    font-family: 'Aileron-UltraLight';
  }
  