
.customers{
    margin-top: 107px;
    width: 100%;
    padding:30px ;
    display: grid;
    place-items: center;
}
.customers-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;  
    flex-wrap: wrap;
   gap: 30%;

}
.cust{
    display: flex;
    align-items: start;
    color: #fff;
}
.cust1{
    display: grid;
    place-items: center;
}
.cust h6{
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    margin-top: 0;
    font-family: 'Aileron-Bold';
}
#smile{
    animation: smile 3s linear infinite;
    position: relative;
}
@keyframes smile {
    0% {
        /* left: 0; */
        transform: scale(0); /* Original size */
    }
    50% {
        /* left: 100px; */
        transform: scale(1); /* Enlarged size */
    }
    100% {
        /* left: 0; */
        transform: scale(1.2); /* Back to original size */
    }
}
.cust h1{
    font-family: 'BebasNeue-Regular';
    font-size:4rem;
    font-weight: 500;
    line-height: 65px;
}
.cust1 p{
    max-width: 300px;
    font-weight: 200;
    font-size: 20px;
    margin-top: 30px;
    color: #fff;
    font-family: 'Aileron-UltraLight';
}

.clients{
    width: 80%;
    padding: 20px;
    overflow: hidden;
    display: grid;
    place-items: center;
    /* margin: 5px auto; */ 
   mask-image: linear-gradient(to right,
                                rgba(0,0,0,0),
                                rgba(0,0,0,1) 20%,
                                rgba(0,0,0,1) 60%,
                                rgba(0,0,0,0)
                                );
    position: relative;
    margin: 2px auto;

}
.clients li{
    list-style: none;
    padding: 15px 25px;
}
@media (max-width: 768px) {
    .cust h1 {
        font-family: 'Bebas Neue', sans-serif;
        font-size: 2.8rem;
        font-weight: 400;
        line-height: 45px;
    }

    #smile {
        animation: smile 3s linear infinite;
        position: relative;
        width: 70%;
    }
}

@media (max-width: 480px) {
    .cust h1 {
        font-size: 2.5rem;
        font-weight: 400;
        line-height: 40px;
    }

    .cust p {
        max-width: 250px;
        font-weight: 200;
        font-size: 16px;
    }
    #smile {
        animation: smile 3s linear infinite;
        position: relative;
        width: 55%;
    }
}

.clients .client-logo{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: scrollleft 10s linear infinite;
    /* padding: 10px; */
    white-space: nowrap;
    /* flex-wrap: wrap; */
    gap: 20px;
 
}

.client-logo img {
    width: 150px;
}

.clients:hover .client-logo{
    animation-play-state: paused;
} 


@keyframes scrollleft{
    0%{
        transform: translateX(100%);
    }
    100%{
        transform: translateX(-100%);
    }
}