
.sectionblg{
    width: 100%;
    margin-top: 6%;
}
.bannerblg{
    top: 0;
    width: 100%;
    height: 40vh;
    background-image: url('../../../../public/assets/images/blog-cover.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #3e3e3e;
}

.contentblg {
    text-align: center;
    margin-left: 8%;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40vh;
    
    /* margin-top: 6%; */
}
.contentblg h1 {
    font-size: 56px;
    font-weight: 500;
    text-align: left;
    font-family: 'BebasNeue-Regular';
    line-height: 58px;
    color: #ffffff;
}
.contentblg h1 span{
    font-size: 40px;
}

.barblg{
  width: 600px;
  height: 8px;
  background-color: #a50fed;
  bottom: 0;
  position: absolute;
}


@media screen and (max-width:1240px){
    
  .contentblg h1 {
    font-weight: 500;
    text-align: left;
    font-family: 'BebasNeue-Regular';
    line-height: 50px;
    color: #ffffff;
}

}

@media screen and (max-width: 768px) {
    .banner10{
        display: block;
        width: 100%;
        height: 100%;
        background-position: 55% 20%;
        background-repeat: no-repeat;
        align-items: center;
        position: relative;
        padding-bottom: 28px;
      }
      .contentblg{
        text-align: center;
        margin-left: 8%;
        display: flex;
        align-items: center;
        width: 100%;
        height: 40vh;
        margin-top: 15%;
    }
    .contentblg h1 {
      font-weight: 500;
      text-align: left;

      line-height: 40px;
      color: #ffffff;
    }
}

@media  (max-width:423px){
  .contentblg h1 {
      font-size: 31px;
      font-weight: 400;
      text-align: left;
  
      line-height: 35px;
      color: #ffffff;
  }
  .contentblg h1 span {
    font-size: 36px;
}

.contentblg h1 {
  font-size: 34px;
}
}

@media  (max-width:580px){
  .contentblg h1 {
      font-size: 37px;
      font-weight: 400;
      text-align: left;
     
      line-height: 41px;
      color: #ffffff;
  }

  .contentblg h1 span{
    font-size: 36px;
}
}

@media (min-width:200px) and (max-width:1165px){
  .contentblg {
    position: relative;
    
    text-align: center;
    margin-left: 8%;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40vh;
    margin-top: 1%;
}

}