.contact{
    margin: 45px 0;
    background-color: #000013 !important;
   
  }
  
  .location4{
    text-align: left;
    color: #fff;
    margin-left: 40px;
  }
  .locate-to-contact h5{
      font-family: 'Aileron-Bold';
  }  
  .btn-primary{
    background-color: transparent !important;
    box-shadow: none !important;
    border: 1px solid #fff !important;
    outline: none !important;
    margin-top: 20px;
    border-radius: 0 !important;
    font-size: 12px !important;
    cursor: pointer;
    font-family: 'Aileron-Bold';
  }
  .btn-primary:hover{
   border: 1px solid #a50fed !important;
  }
  .address4{
    padding: 20px 0;
  }
  .address4 h6{
    color: #a50fed;
    font-size: 18px;
    font-family: 'Aileron-Bold';
  }
  .locate-address4{
    display: flex;
    align-items: center;
    gap: 18%;
  }
  
  .list-group-item4{
    background-color: transparent !important;
    border:  none !important;
    display: flex !important;
    align-items: center !important;
    color: #fff !important;
    gap: 8px;
    font-size: 14px;
    padding: 8px 0 !important;
    font-family: 'Aileron-Light';
  }
  
  .icon4{
    color: #a50fed;
    font-size: 16px;
  }
  .butt{
    padding: 6px 50px !important;
  }
  
  .line4{
    height: 1px;
    width: 600px;
    background-color: #ccc;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .enquiry-form{
    width: 100% !important;
    max-width: 500px !important;
    text-align: left;
    color: #fff;
    margin-right: 50px;
    /* padding-left: 10% !important; */
  }
  .enquiry-form p{
    /* font-size: 15px; */
    font-weight: 400;
    margin-top: 30px;
    font-family: 'Aileron-Regular';
  }
  
  #form{
    margin-top: 50px;
  }
  
  
  
  .input-box{
    position: relative;
    width: 100%;
    margin: 10px 0;
  }
  .input-box input,textarea{
    width: 100%;
    padding: 8px 30px;
    background-color: #ffffff;
    color: #000000;
    /* font-size: 14px; */
    border-radius: 50px;
    outline: none;
  }
  textarea{
    border-radius: 20px;
  }
  textarea span{
    position: absolute;
    left: 0;
    padding: 12px 30px;
    color: #5d5d5d;
    text-transform: uppercase;
    pointer-events: none;
    font-size: 13px;
    transition: 0.5s;
  }
  .input-box span{
    position: absolute;
    left: 0;
    padding: 12px 30px;
    color: #5d5d5d;
    text-transform: uppercase;
    pointer-events: none;
    font-size: 13px;
    transition: 0.5s;
    font-family: 'Aileron-Thin';
  }
  
  textarea :valid ~ span,
  textarea:focus ~ span
  {
    color: #a50fed;
    transform: translateX(20px) ;
    font-size: 0.6em;
    padding: 0 10px;
    font-weight: 500;
  }
  .input-box input:valid ~ span,
  .input-box input:focus ~ span
  {
    color: #a50fed;
    transform: translateX(20px) ;
    font-size: 0.6em;
    padding: 0 10px;
    font-weight: 500;
  }
  /* chrome*/
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button{
    -webkit-appearance: none;
  }
  
  #input-field{
    color: black;
  }
  /*firefox*/
  /* input[type="number"]{
    -moz-appearance: textfield;
  } */
  .button-sec{
    width: 100% !important;
    display: grid !important;
    place-items: center !important;
    top: 0 !important;
  }
  .submit{
    border: none !important;
    background-color: #a50fed !important;
    color: #fff !important;
    padding: 10px 50px !important;
    border-radius: 50px !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    margin-top: 0 !important;
    font-family: 'Aileron-Bold';
  }
  .submit:hover{
    border: none !important;
    background-color: rgb(171, 51, 232) !important;
  }

  @media (min-width:200px) and (max-width:999px){
     .enquiry-form{
      display: contents;
      text-align: -webkit-center;
      margin-right: 0px;
     }

  }

  @media  (max-width:600px){
  
    .location4{
      text-align: left;
      color: #fff;
      margin-left: 0px;
    }
  }