@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import 'animate.css/animate.min.css';
@font-face {
  font-family: 'Aileron-UltraLight';
  src: url('../public/Fonts/Aileron-UltraLight.otf') format('opentype');
} 
@font-face {
  font-family: 'Aileron-Bold';
  src: url('../public/Fonts/Aileron-Bold.otf') format('opentype');
} 
@font-face {
  font-family: 'Aileron-Light';
  src: url('../public/Fonts/Aileron-Light.otf') format('opentype');
} 
@font-face {
  font-family: 'Aileron-Regular';
  src: url('../public/Fonts/Aileron-Regular.otf') format('opentype');
} 
@font-face {
  font-family: 'BebasNeue-Regular';
  src: url('../public/Fonts/BebasNeue-Regular.ttf') format('opentype');
} 
@font-face {
  font-family: 'Aileron-Thin';
  src: url('../public/Fonts/Aileron-Thin.otf') format('opentype');
} 


@font-face{
  font-family: Eurostile LT Pro Unicode;
  src: url('../public/Fonts/EurostileLTProUnicode.otf') format('opentype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*{
  margin: 0;padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}
.container-fluid{
  overflow: hidden;
  width: 100%;
  
}
.wrapper{
  width: 100%;
  overflow: hidden;
}

body {
  width: 100vw;
  font-family: 'Aileron';
  background-color: #000013 !important;
  overflow-x: hidden;
   /* display: flex;
   align-items: center;
   justify-content: center; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
p{
  font-family: Aileron;
}
.lines{
  font-size: 18px !important;
  padding-right: 13px;
}

