
.news-details{
    width: 100%;
    padding: 30px;
    /* margin: 30px 0; */
  }
  
  .newsDetails_content h5{
    color: #a50fed;
    font-size: 34px;
    font-family: 'Aileron-Bold';
    text-align: center;
  }
  .newsDetails_content p{
    width: 100%;
    /* max-width: 1000px; */
    color: #fff;
    text-align: justify;
    font-weight: 400 !important;
    padding:10px 0 20px;
    font-family: 'Aileron-Regular';
  }
  
  .management img{
    width: 100%;
   object-fit: cover;
  }
  
  .management-details{
    padding: 30px 0;
  }
  .management-details p{
    color: #ffff;
    font-family: 'Aileron-Regular';
  }

  @media screen and (max-width:1240px){
    .management-details p{

        text-align: justify;
      }
  }
  @media screen and (max-width: 768px) {
    
.newsDetails_content p{
    padding: 0 10px;
    }
    .management-details p{
      padding: 0 10px;
    }
    
  }