
.security6{
    width: 100%;
    /* padding: 30px; */
  }
  .sec6{
    width: 100% !important;
    margin: 0 !important;
  }
  
  
  .security-operation6{
    background: linear-gradient(to top, #7d02d9,#a50fed );
    width: 100% !important;
    padding: 20px 30px!important;
    color: #fff;
    /* margin-top: 15px !important; */
    margin-bottom: 40px;
    display: grid;
    place-items: center;
    text-align: center;
    
  
  }
  .security-operation6 img{
    width: 200px;
  }
  .security-operation6 h2,#para{ 
    font-weight: 200 ;
    max-width: 550px ;
    font-family: 'Aileron-UlraLight';
  }
  #para{
    max-width: 400px !important;
    font-weight: 200 !important;
    font-size: 15px;
    font-family: 'Aileron-UltraLight';
    /* margin-top: 10px !important; */
  }

  @media screen and (max-width:576px){
 .security-operation6 h2{
    font-size: 20px;
 }
 #para{
    font-size: 13px;
 }
  }