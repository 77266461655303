
  /*footer*/


  hr{
    width: 100%;
    background-color: #ccc;
  }
  footer{
    padding: 20px 50px;
    border-top: 1px solid #3e3e3e;
    border-bottom: 1px solid #3e3e3e
  }
  #footer-logo{
    width: 250px;
  }
  .links{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .menulist-social{
    display: flex;
    align-items: center;
    padding: 15px 0;
    flex-wrap: wrap;
  }
  .footer-menu  {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .footer-menu li{
    padding:  10px;
   
  }
  .footer-menu li a{
    text-decoration: none;
    color: #fff;
    font-family: 'Aileron-Light';
  }

  .socialmedia-icons{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .socialmedia-icons li{
    padding: 10px;
  }
  .sent p{
    color: #fff;
    font-family: 'Aileron-Light';
  }
  .form-control{
    border-radius: none !important;
    padding: 10px 0;
  }
  .input-group-text{
    cursor: pointer;
  }

  .footer-menu li a:hover{
    color: #a00dec;
  }
  .copyright{
    padding: 0 5px;
    margin-top: -20px !important;
  }
  .copyright p{
    color: #ccc;
    font-family: 'Aileron-Light';
  }

  @media screen and (max-width:1145px) {
    footer{
        width: 100%;
        display: grid;
        place-items: center;
      }
      .links{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
       
      }
      .footer-menu li{
        padding:  10px;
        text-align: center;
      }
      .menulist-social{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px 0;
        flex-wrap: wrap;
      }

      
}


@media screen and (max-width:768px) {
  .copyright p {
    color: #ccc;
    margin-top: 21px;
  }
}

@media screen and (max-width:600px) {
  .menulist-social,
  .footer-menu{
    display: block;
  }
}