.Implementation_2{
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    display: grid;
    place-items: center;
  }
  .Implementation_2 h1{
    font-family: 'BebasNeue-Regular',;
    color: #fff;
    text-transform: uppercase;
  }
  .Implementation_2 p{
    text-align: left;
    width:100% !important;
    max-width: 60% !important; 
    color: #ffffff;
    font-size: 15px;
    text-align: justify;
    font-family: 'Aileron-UltraLight';
  }
  
  
  .analysDevelop_content{
    width: 100%;
    max-width: 950px;
    margin: 40px 0;
    padding: 0 30px;
  
  }
  .two_columns{
    display: flex;
    text-align: left;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    margin-top: 30px ;
  }
  
  .column1 h4{
    color: #a510ed;
    font-weight: 300;
    font-family: 'Aileron-UltraLight';
  }
  .column1 p{
    max-width: 550px !important;
    font-size: 14px;
    /* word-spacing: -2.5px; */
    font-family: 'Aileron-UltraLight';
  }
  /* #paragraphodo{
    margin-top: -4%;
  } */
  
 
  .contentListing li{
    max-width: 550px !important;
    list-style: square !important;
    text-align: left;
    /* display: flex; */
    color: #fff;
    font-size: 13px;
    padding: 8px 0;
    text-align: justify;
  }
  .barod{
    display: flex;
    align-items: end;
  
  }
  .column2 img{
    width: 300px;
  }
  .bar1od{
    width: 12px;
    background-color: #a50fed;
    height: 120px;
    margin-bottom: 0;
  }
  
  .odoo_implementationSection h2{
    font-size: 48px;
  }
  
  
  .odoo_deployment{
    width: 100%;
    margin: 60px 0;
    background-color: #fff;
    position: relative;
    display: grid;
    place-items: center;
  }
  .odoo_deploymentSection{
    width: 100% !important;
    padding: 20px 30px!important;
    color: #000000;
    margin-top: 15px !important;
    display: grid;
    place-items: center;
    text-align: center;
  }
  .odoo_deploymentSection h1{ 
    font-family: 'BebasNeue-Regular';
    
  }
  .odoo_deploymentSection p{
    width: 100% !important;
    max-width: 988px !important;
    font-weight: 200 !important;
    font-size: 14px;
    z-index: 9999;
    font-family: 'Aileron-UltraLight';
  
  }
  .triangle{
    width: 0;
    height: 0;
    margin: 10px;
    border-bottom: 100px solid #ffffff;
    border-left: 80px solid transparent;
    border-right: 80px solid transparent;
    rotate: 180deg;
    position: absolute;
    bottom: -25%;
  }
  

  
  .ERP_implementation{
    width: 100%;
    color: #ffff;
    padding: 15px 70px ; 
    margin: 50px 0;
  }
  
  .row1-1{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 50px 0;
  }
  .headodo h1{
    max-width: 350px;
   font-size: 48px;
   font-family: 'BebasNeue-Regular';
  
  }
  .erpImple_cont{
    padding: 0 15px;
    border-left: 2px solid #ccc;
   max-width: 565px;
  }
  .erpImple_cont p{
    text-align: justify;
    font-size: 14px;
    font-family: 'Airelon-UltraLight';
  }
  .erpImple_cont2{
    padding: 0 15px;
    border-left: 2px solid #ccc;
   max-width: 565px;
  }
  .erpImple_cont2 p{
    text-align: justify;
    font-size: 14px;
    font-family: 'Aileron-UltraLight';
  }
  
  .row2-1{
    margin: 50px 0;
    text-align: center;
    background: linear-gradient(to top,  #7d02d9 8%, #a50fed 50% );
    padding: 40px 20px;
    display: grid;
    place-items: center;
  }
  .row2-1 h6{
    font-size: 22px;
    font-weight: 300;
    font-family: 'Aileron-UltraLight';
  }
  .row2-1 p{
    font-size: 14px;
    max-width: 750px !important;
    font-family: 'Aileron-UltraLight';
  }
  
  
  
  
  
  
  
  
  .benefitsForOdoo_imple{
    width: 100% !important;
    padding: 0 30px  30px !important;
    display: flex !important;
    align-items: start !important;
    justify-content: center !important;
    margin-top: 30px;
  }
  
  .colu1{
  
  margin-left: 15px;
  }
  
  .benefits{
    width: 100% !important;
    display: flex !important;
    gap: 15px !important;
    text-align: left;
    /* margin-top: -5%; */
  }
  .benefits-content h6{
    color: #fff;
    font-size: 23px;
  }
  .benefits-content p{
    width: 100% !important;
    max-width: 500px !important;
    font-size: 14px;
    color: #fff;
    text-align: justify;
    
  }
  .benefits img{
    width: 60px;
  }
  
  .row1-2{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 30px 0;
  }
  .head2 h1{
    max-width: 350px;
   font-size: 3rem;
   font-family: 'BebasNeue-Regular';
  }
  .erpImple_cont{
    padding: 0 15px;
    border-left: 2px solid #ccc;
  }
  .erpImple_cont p{
    text-align: justify;
    font-size: 14px;
    font-family: 'Aileron-UltraLight';
  }
  
  
  
  
  .keyComponents{
    width: 100% !important;
    padding: 0 70px;
  }
  .col1-1{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 35px;
   margin-top: -2%;
  }
   .number{
    font-size: 7rem;
    color: #a510ed;
    font-family: 'Aileron-Ultralight';
    font-weight: lighter;
  }
  .row_col h3{
    color: #ffff;
    font-weight: 200;
    font-family: 'Aileron-Ultralight';
  }
  .row_col p{
    width: 100%;
    max-width: 100% !important;
    color: #ffff;
    font-size: 14px;
    text-align: justify;
    font-family: 'Aileron-Ultralight';
  }
  .row_div li{
    list-style: square;
    color: #fff;
    font-size: 14px;
    padding: 4px 0;
    font-family: 'Aileron-UltraLight';
  }
  .col-class{
    margin-top: 0 !important;
    display: flex;
    align-items: start;
  }
  
  
  .contact-btnodo{
    margin: 50px 0;
    display: grid;
    place-items: center;
  }
  .contactodo{
   border-radius: 50px !important;
    background-color: transparent !important;
    border: 1px solid #a50fed !important;
    box-shadow: none !important;
    outline: none !important;
    padding: 8px 100px !important;
    font-size: 15px !important;
    font-family: 'Aileron-Bold';
    color: #a50fed;
  }
  .contactodo:hover{
    border: 1px solid #bb55ea !important;
    font-weight: 500 !important;
    color: #a50fed;
  }

  @media screen and (max-width:1240px){
    .benefitsForOdoo_imple{
      width: 100% !important;
      padding: 0 30px  30px !important;
      display: block !important;
      align-items: start !important;
      justify-content: center !important;
      margin-top: 30px;
    }
  }
  @media (max-width:940px){
  
    .two_columns{
      display: inline-block;
      text-align: left;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 20px;
      margin-top: 30px ;
      border-bottom: 1px solid #ccc;
      padding: 10px 0;
    }
    .erpImple_cont{
      padding: 0 15px;
      border-left: none;
     max-width: 565px;
    }
  
  }

  @media (max-width:600px){
    .triangle{
      width: 0;
      height: 0;
      margin: 10px;
      border-bottom: 100px solid #ffffff;
      border-left: 80px solid transparent;
      border-right: 80px solid transparent;
      rotate: 180deg;
      position: absolute;
      bottom: -15%;
    }
  
    .column2 img{
      width: 100%;
    }

      .col1-1{
        width: 100%;
        display: grid;
        place-items: center;
        gap: 0;
       margin-top: 10px;
       border: 1px solid #a50fed;
       padding: 0 10px;
      }
      .contactodo{
        border-radius: 50px !important;
         background-color: transparent !important;
         border: 1px solid #a50fed !important;
         box-shadow: none !important;
         outline: none !important;
         padding: 8px 100px !important;
         font-size: 15px !important;
         color: #a50fed;
       }

       .headodo h1{
        max-width: 350px;
       font-size: 38px;
       
      
      }
      .benefits{
        width: 100% !important;
        display: block !important;
        gap: 15px !important;
        text-align: left;
        margin-top: -5%;
      }
      .benefits-content p{
        width: 100% !important;
        max-width: 600px !important;
        font-size: 14px;
        color: #fff;
        text-align: justify;
      }
      .benefits img{
        width: 60px;
        margin-bottom: 10px;
      }
      
      
  }

  @media (min-width:200px) and (max-width:580px){
    .benefitsForOdoo_imple{
      width: 100% !important;
      padding: 0 0 30px !important;
      display: block !important;
      align-items: start !important;
      justify-content: center !important;
      /* flex-wrap: wrap !important; */
      margin-top: 30px;
    }
    .colu1{
  
      margin-left: 0;
      }
      .row2-1 p {
        text-align: justify;
      }
  }
  /* @media screen and (max-width:700px){
      
 .colu1{
    display: grid !important;
    place-items: center !important;
   }
    
      .benefits{
        width: 100% !important;
        display: grid !important;
        place-items: center !important;
        gap: 15px !important;
        height: auto !important;
        text-align: center;
      }
      .benefits-content p{
        width: 100% !important;
        max-width: 100% !important;
        font-size: 14px;
        color: #fff;
        text-align: center;
      }
  } */
