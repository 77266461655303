
.section11{
    width: 100%;
    margin-top: 6%;
}
.banner11{
    top: 0;
    width: 100%;
    height: 40vh;
    background-image: url('../../../../public/assets/images/newsDetails-cover.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #3e3e3e;
}

.content11 {
    text-align: center;
    margin-left: 8%;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40vh;
    /* margin-top: 6%; */
}
.content11 h1 {
    font-size: 52px;
    text-align: left;
    font-family: 'BebasNeue-Regular';
    line-height: 50px;
    color: #ffffff;
}
.content11 h1 span{
    font-size: 36px;
}

.bar11{
  width: 600px;
  height: 8px;
  background-color: #a50fed;
  bottom: 0;
  position: absolute;
}
@media screen and (max-width:1240px){
    
  .content11 h1 {
    font-size: 48px;
    font-weight: 500;
    text-align: left;
 
    line-height: 50px;
    color: #ffffff;
}

}

@media screen and (max-width: 768px) {
    .banner11{
        display: block;
        width: 100%;
        height: 100%;
        /* background-image: url(../images/newsDetails-cover.jpg); */
        background-position: 55% 20%;
        background-repeat: no-repeat;
        align-items: center;
        position: relative;
        padding-bottom: 28px;
      }
    
      .content11 {
        text-align: center;
        margin-left: 8%;
        display: flex;
        align-items: center;
        width: 100%;
        height: 40vh;
        margin-top: 15%;
    }
    .content11 h1 {
      font-size: 36px;
      font-weight: 500;
      text-align: left;
 
      line-height: 40px;
      color: #ffffff;
    }

    .content11 h1 span{
      font-size: 33px;
  }
  
    
}