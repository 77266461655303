
.zoho_authorized{
    width: 100%;
    margin: 30px 0;
  }
  
  
  
  .ZA_col{
    background: linear-gradient(to top,  #7d02d9 8%, #a50fed 50% );
    width: 100% !important;
    padding: 20px 30px!important;
    color: #fff;
    margin-top: 15px !important;
    display: grid;
    place-items: center;
    text-align: center;
  
  }
  .ZA_col img{
    width: 200px;
  }
  .ZA_col h2{ 
    font-weight: 200 !important;
    max-width: 700px !important;
    padding: 12px 0;
    font-family: 'Aileron-UltraLight';
  }
  #row_content1{
    max-width: 900px !important;
    font-weight: 200 !important;
    font-size: 15px;
    font-family: 'Aileron-UltraLight';
    /* margin-top: 10px !important; */
  }