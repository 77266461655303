
.odoo_implementation{
  width: 100%;
  margin: 30px 0;
}



.odoo_implementationSection{
  background: linear-gradient(to top,  #7d02d9 8%, #a50fed 50% );
  width: 100% !important;
  padding: 40px 30px!important;
  color: #fff;
  margin-top: 15px !important;
  display: grid;
  place-items: center;
  text-align: center;

}
.odoo_implementationSection img{
  width: 200px;
}
.odoo_implementationSection h2, #row_content{ 
  font-weight: 200 !important;
  max-width: 550px !important;
  padding: 12px 0;
  font-family: 'Aileron-UltraLight';
}
#row_content{
  max-width: 900px !important;
  font-weight: 200 !important;
  font-size: 15px;
  font-family: 'Aileron-UltraLight';
  /* margin-top: 10px !important; */
}
@media (max-width: 768px) {
  #row_content {
    max-width: 900px !important;
    font-weight: 200 !important;
    font-size: 15px;
    text-align: justify;
  }
}
