
.cloud8 {
    width: 100%;
    /* padding: 30px; */
   
  }
  .Cls{
    width: 100% !important;
    margin: 0 !important;
  }
  /* font-weight: 300;
  font-size: 54px; */
  
  .cloud-ser8{
    background: linear-gradient(to top, #7d02d9,#a50fed );
    width: 100% !important;
    padding: 20px 30px!important;
    color: #fff;
    /* margin-top: 15px !important; */
    display: grid;
    place-items: center;
    text-align: center;
  
  }
  .cloud-ser8 h2{
    font-weight: 200;
    font-size: 55px;
    font-family: 'Aileron-UltraLight';
    max-width: 1100px;

  }
  .cloud-ser8 img{
    width: 180px;
  }
  .cloud-ser8  #para8{ 
    font-weight: 400 ;
    max-width: 700px ;
    font-family: 'Aileron-UltraLight';

  }
  #para8{
    max-width: 988px !important;
    font-weight: 100 !important;
    font-size: 19px;
    /* color: #b0afaf; */
    /* margin-top: 10px !important;
    /* margin-top: 10px !important; */
  }

  @media screen and (max-width: 768px) {
    #para8{
      max-width: 988px !important;
      font-weight: 100 !important;
      font-size: 19px;
      /* color: #b0afaf; */
      /* margin-top: 10px !important;
      /* margin-top: 10px !important; */
    }

    .cloud-ser8 h2{
      font-weight: 200;
      font-size: 40px;
    }
  }

  @media screen and (max-width: 510px) {
    #para8{
      max-width: 988px !important;
      font-weight: 100 !important;
      font-size: 19px;
      /* color: #b0afaf; */
      /* margin-top: 10px !important;
      /* margin-top: 10px !important; */
    }

    .cloud-ser8 h2{
      font-weight: 200;
      font-size: 32px;
    }
  }