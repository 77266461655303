
.zag{
  width: 100%;
}
.zag img{
  width: 320px;
}

.newsroom{
  width: 100%;
  padding:30px ;
  display: grid;
  place-items: center; 
}

.doted-images{
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 15px;
  margin-right: 40px;
  margin-bottom: 0;
}

.dot-animation{
  width: 100%;
  display: flex;
  align-items: center;
  gap: 25px;
  margin-bottom: 25px;
}
.dot{
  width: 9px;
  height: 9px;
  background-color: #a00dec;
  animation: color 3s linear infinite;
}
@keyframes color{
  0%{
      background-color: #a00dec;
      transform: translateX(100%);
  }
  100%{
      background-color: #c68de3;
      transform: translateX(-100%);
  }
}

.newsroom-sec{
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: center;  
  flex-wrap: wrap;
  gap: 30%;
}
.news{
  display: flex;
  align-items: center;
  color: #fff;
}
.news h6{
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  font-family: 'Aileron-Bold';
 
}
.news h1{
  font-family: 'BebasNeue-Regular';
  font-size: 64px;
  line-height: 65px;
  margin-left: 10px;
}

.sahara-announcement .sahara{
  width:100px
}


.sahara-announcement{
  width: 100%;
  display: grid;
  place-items: center;
}

.sahara-technologies {
  width: 90%;
  height: 100%;

}
.newproject-announce{
  padding: 0 20px;
}

.swiper-slide {
  text-align: center;
  /* background: #fff; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 10px;
}
.new-project{
  display: grid;
  place-items: center;
}
.swiper-slide .h1{
  color: #fff;
  font-size: 2.6rem;
  font-weight: 200;
  text-transform: capitalize;
  max-width: 800px;
  font-family: 'Aileron-UltraLight';
}
.swiper-slide p{
  color: #fff;
  font-size: 16px;
  font-weight: 100;
  max-width: 750px;
  text-align: center;
  font-family: 'Aileron-UltraLight';
}
.service-content .arrowimage{
  width: 80%;
}
.swiper-button-next::after,
.swiper-button-prev::after{
  content: none !important;
}

@media (max-width: 768px) {
  .news h1{
    /* font-family: 'Bebas Neue', sans-serif; */
    font-size: 3.5rem;
    line-height: 65px;
    margin-left: 10px;
  }
  .service-content .arrowimage{
    width: 50%;
  }

  .swiper-slide .h1{
    color: #fff;
    font-size: 28px;
    font-weight: 200;
    text-transform: capitalize;
    max-width: 800px;
  }

  .sahara-announcement .sahara{
    width:77px
  }
}

.nex-arrow{
  width: 120%; 
 }
@media (max-width: 480px) {
  .news h1{
    /* font-family: 'Bebas Neue', sans-serif; */
    font-size: 2.6rem;
    line-height: 50px;
    margin-left: 10px;
  }

  .service-content .arrowimage{
    width: 30%;
  }

  .newsroom{
    width: 100%;
    padding:0px ;
    display: grid;
    place-items: center; 
  }

  .swiper-slide p {
    color: #fff;
    font-size: 13px;
  }

  .swiper-slide .h1 {
    color: #fff;
    font-size: 20px;
  }
  .zag img {
    width: 245px;
}
 
}
@media (max-width: 450px) {
  .news h1{
    /* font-family: 'Bebas Neue', sans-serif; */
    font-size: 2rem;
    line-height: 40px;
    margin-left: 10px;
  }
  .news h6 {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    font-size: 19px;
}
}