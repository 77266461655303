.deploymentContent_section {
    width: 100% !important;
    max-width: 950px;
    margin: 40px auto;
    padding: 0 25px;
  }
  
  .deployment .list-group-item {
    background-color: transparent !important;
    border: none !important;
    color: #ffff;
    display: flex;
    align-items: end;
    justify-content: space-between;
   
    border-radius: 0 !important;
    border-bottom: 1px solid #a50fed !important;
    padding: 20px 0 !important;
    margin-top: 10px;
  }
  
  .deployment_content h3 {
    font-weight: 300;
    font-family: 'Aileron-UltraLight';
  }
  
  .deployment_content p {
    font-size: 13px;
    max-width: 700px !important;
    text-align: justify;
    font-family: 'Aileron-UltraLight';
  }
  
  .deployment .list-group-item img {
    width: 120px;
  }
  
  @media (max-width: 600px) {
    .deployment .list-group-item {
      display: grid !important;
      place-items: center;
     
    }
  }
  