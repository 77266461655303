.sectionzoho{
  width: 100%;
  margin-top: 6%;
}
.bannerzoho{
  top: 0;
  width: 100%;
  height: 90vh;
  background-image: url('../../../../public/assets/images/zoho_coverImg.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  position: relative;
}

.main-contentzoho{
width: 100% !important;
display: flex;
align-items: center;
padding: 0 50px;
gap: 20px;
/* background-color: yellow; */
}

.side-barzoho{
display:grid;
place-items: center;

}
.linezoho{
height: 200px;
width: 6px;
background: linear-gradient(to bottom, #000,#a50fed);
}
.circlezoho{
width: 20px;
height: 20px;
background-color: #a50fed;
border-radius: 50px;
margin: 5px 0;
display: grid;
place-items: center;
}
.black-circlezoho{
width: 15px;
height: 15px;
border: 3px solid #000;
border-radius: 50px;

}
.line1zoho{
height: 200px;
width: 6px;
background: linear-gradient(to top, #000,#a50fed);
}

.zoho{
padding:0  0 10px;
}
.zoho img{
width: 180px;
}
.contentzoho {
  text-align: left;
  margin-left: 5%;
  width: 100%;
  
}
.contentzoho h1 {
font-size: 2.5rem;
  font-weight: 500;
  text-align: left;
  font-family: 'BebasNeue-Regular' ;
  /* line-height: 50px; */
  color: #ffffff;
}
.contentzoho h5 {
  font-weight: 500;
  /* line-height: 58px; */
  color: #a50fed;
  max-width: 400px !important;
  font-family: 'Aileron-Bold';
}
.contentzoho p {
color: #ffffff;
width: 100%;
max-width: 300px !important;
font-family: 'Aileron-Light';
}
.list8{
padding: 20px 0 !important;
}
.list8 li{
display: flex;
align-items: center;
gap: 10px;
text-align: left;
color: #ffff;
font-size: 14px;
padding: 3px 0;
line-height: 16px;
}
.list8 li img{
width: 20px;
}



/*form*/
.heroFormzoho .zohofrom{
margin-left: auto;
}

.zohofrom{
background: linear-gradient(to top, #7d02d9 8%,#a50fed 40% );
width: 400px;
margin-right: 6%;
padding: 40px 20px;
border-radius: 30px;

}
.zohofrom h6{
text-align: center;
color: #fff;
padding: 8px 0;
font-weight: 900;
font-family: 'Aileron-Bold';
}
.flagzoho p{
  font-family: 'Aileron-UltraLight';
}
.input-boxzoho{
position: relative;
width: 100%;
margin: 10px 0;
display: flex;
align-items: center;
border: 1px solid #b4b0b0;
}
.input-boxzoho img{
margin-right: 10px;
}

.input-boxzoho input{
width: 100%;
padding: 8px 30px;
border: none !important;
background-color: transparent;
color: #fff !important;
font-size: 14px;
outline: none;
flex: 1;
}
textarea{
border-radius: 20px;
}
.input-boxzoho span{
position: absolute;
left: 0;
padding: 12px 30px;
color: #ffffff;
text-transform: uppercase;
pointer-events: none;
font-size: 13px;
transition: 0.5s;
font-family: 'Aileron-UltraLight';
}

.input-boxzoho input:valid ~ span,
.input-boxzoho input:focus ~ span
{
  color: #f8f8f8 !important;
transform: translateX(20px) translatey(-13px);
font-size: 0.6em;
padding: 0 10px;
font-weight: 500;
}
/* chrome*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button{
-webkit-appearance: none;
}

/*firefox*/
/* input[type="number"]{
-moz-appearance: textfield;
} */
.button-seczoho{
width: 100% !important;
display: grid !important;
place-items: center !important;
top: 0 !important;
}
.submitzoho{
width: 100%;
background-color: transparent !important;
border: 2px solid #fff !important;
color: #ffffff !important;
padding: 10px 50px !important;
border-radius: 50px !important;
font-weight: 500 !important;
font-size: 15px !important;
margin-top: 0 !important;
text-transform: capitalize;
font-family: 'Aileron-Bold';
}
.submitzoho:hover{
border: 1px solid #c277e4 !important;
}

/* .bar{
width: 600px;
height: 8px;
background-color: #a50fed;
bottom: 0;
position: absolute;
} */

#phone-number{
margin-left: 40px;
}

.flagzoho{
display: flex;
align-items: center;
padding: 0 10px;
border-right: 1px solid #ffff;
}
.flagzoho p{
color: #fff;
padding-top: 14px;
}



.services_list1zoho{
width: 100%;
display: grid;
place-items: center;
padding: 10px;
}
.row_itemzoho{
width: 100%;
max-width: 1210px;
display: flex;
align-items: center;
justify-content: center;
flex-wrap: wrap;

}
.services_list1zoho li{
display: grid;
place-items: center;
padding: 10px 25px;
color: #fff;
margin: 0 20px;
}
.services_list1zoho li img{
width: 25px;
}

@media screen and (max-width:1240px){
  
  .bannerzoho{
    top: 0;
    width: 100%;
    height: 100%;
    /* background-image: url(../images/zoho_coverImg.jpg); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    position: relative;
}

   .contentzoho{
      position: relative;
      margin-left: 8%;
      width: 100%;
      /* margin-top: 6%; */
  }
  .contentzoho h1 {
      font-size: 3.5rem;
      font-weight: 500;
      text-align: left;
      /* font-family: 'Bebas Neue', sans-serif; */
      line-height: 58px;
      color: #ffffff;
  }

}

@media (min-width:769px) and (max-width:1150px){
  .contentzoho {
    position: relative;
    margin-left: 8%;
    width: 100%;
    height: auto;
    margin-top: 10%;
}

.heroFormzoho .zohofrom{
  margin-left: -45px;
  margin-top: 35%;
}

.phoneicon{
  position: absolute;
  top: 0;
  right: -5px;
}


.zohofrom{
  /* background: linear-gradient(to top, #7d02d9,#a50fed ); */
  width: 100%;
  max-width: 400px;
  margin-right: 16%;
  padding: 40px 20px;
  border-radius: 30px;
}
.input-boxzoho input{
  width: 100%;
  padding: 8px 30px;
  border: none !important;
  background-color: transparent;
  color: #fff !important;
  outline: none;
  flex: 1;
}

}

@media screen and (max-width: 768px) {
  .bannerzoho{
    display: block;
    width: 100%;
    height: 100%;
    /* background-image: url(../images/zoho_coverImg.jpg); */
    background-position: 55% 20%;
    background-repeat: no-repeat;
    align-items: center;
    position: relative;
    padding-bottom: 28px;
  }
  .main-contentzoho {
    width: 100% !important;
    display: flex;
    align-items: center;
   padding: 0;
}
.contentzoho {
  position: relative;
  margin-left: 0;
  width: 100%;
  height: auto;
  margin-top: 115px;
}
.heroFormzoho{
padding: 25px;
}
.zohofrom{
width: 100%;
margin-left: 0;
}


}

@media (min-width:200px) and (max-width:580px){
  .contentzoho h1 {
    font-size: 2.8rem;
    font-weight: 500;
    text-align: left;
    /* font-family: 'Bebas Neue', sans-serif; */
    line-height: 45px;
    color: #ffffff;
}


  .bannerzoho{
    display: block;
    width: 100%;
    height: 100%;
    /* background-image: url(../images/zoho_coverImg.jpg); */
    background-position: 55% 20%;
    background-repeat: no-repeat;
    align-items: center;
    position: relative;
    padding-bottom: 28px;
  }
  .main-contentzoho {
    width: 100% !important;
    display: flex;
    align-items: center;
   padding: 0;
}
.contentzoho {
  position: relative;
  margin-left: 0;
  width: 100%;
  height: auto;
  margin-top: 115px;
}
.heroFormzoho{
padding: 25px;
}
.zohofrom{
width: 100%;
margin-left: 0;
}
}