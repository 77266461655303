.second {
  width: 100%;
  height: auto;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
}



.main-head {
  display: flex !important;
  align-items: start;
  justify-content: start;
  padding: 10px 0 !important;
}

.main {
  display: flex;
  align-items: start;
}

.main h6 {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  font-family: 'Aileron';
  color: #fff;
  display: inline;
  margin-left: 0;
}

.main h1 {
  font-size: 7em !important;
  font-weight: 500;
  text-align: left;
  font-family: 'Bebas Neue', sans-serif;
  line-height: 90px;
  color: #fff;
  margin-left: 0;
}

.main-head::after{
  content: "";
  width: 8px;
  height: 235px;
  background-color: #676767;
  margin: 0 18px;
  margin-top: 38%;
  margin-left: -63px;
}


/* .line {
  width: 8px;
  height: 210px;
  background-color: #676767;
  margin-bottom: 6%;
  margin-left: -5%;
} */

.col2-about {
  display: flex;
  align-items: end;
  /* flex-wrap: wrap; */
}

.cont_slider {
  width: 400px;
 
}

.text h5 {
  color: #fff;

  font-family: 'Aileron-UltraLight';
  font-weight: 100;
  margin-left: -100px;
  font-size: 30px;
  max-width: 300px;
  text-align: left;
}

.text h5 img {
  margin-left: 0;
  margin-top: 10px;
  width: 150px;
}



@keyframes zoomIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}




.odoo-logo {
  animation: zoomIn 1s ease-in-out;
}

.animate-text {
  display: inline-block;
  animation: zoomIn 1s ease-in-out;
}

#runner {
  animation: fadeIn 4s ease-in-out; 
}

@keyframes movearrow {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}


.second {
    width: 100%;
    /* background-color: #000013 !important; */
    display: block;
    align-items: center;
    justify-content: left;
    padding: 30px 0;
    
    
  }
 
  
  .achieve-more {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  

  #runner {
    width: 39%;
    height: auto;
    margin-left: -10%;
  }
  


  
  .main-head {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 0;
    flex-wrap: wrap; /* Add this to handle smaller screens */
  }

.main{
  display: flex;
  align-items: start;
}
.main h6{
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  font-family: 'Aileron';
  color: #fff;
  display: inline;
  margin-left: 0;
}
.main h1{
  font-size: 7em !important;
  font-weight: 500;
  text-align: left;
  font-family: 'BebasNeue-Regular' ;
  line-height: 100px;
  color: #fff;
  margin-left: 0;
}
  .main h1 .highlight {
    color: #b0402f;
    text-transform: uppercase;
  }
  
  .line {
    width: 8px;
    height: 221px;
    background-color: #676767;
    margin-top: 38%;
    margin-left: -12%;
}
 
.main-head h5 {
  color: #fff;
  font-family: 'Aileron';
  font-weight: 200;
  margin-top: 47%;
  margin-left: 14px;
  font-size: 25px;
  max-width: 250px;
 
}
  
  .main-head h5 img {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
  }
  
  .animation-div {
    width: 100%;
  position: absolute;
  }
  
  .arrow-img {
    width: 200px;
    height: 200px;
    background: url('../../../public/assets/images/arrows-icon.png') center / cover no-repeat;
    z-index: 9999;
  }

.slider {
  transition: opacity 1s ease-in-out;
  opacity: 0;
}




/*Responsive*/
@media (max-width:1055px){
  .animation-div {
    width: 100%;
  position: relative !important;;
  }
}

@media (max-width:1200px){
  .content h1 {
      font-size: 4rem;
      font-weight: 500;
      text-align: left;
      font-family: 'Bebas Neue', sans-serif;
      line-height: 55px;
  }

  .col2-about{
      width: 35%;
      display: block;
      align-items: end;
      justify-content: left;


    }
    .second{
      /* width: 100%; */
      height: 100vh;
      background-color: #000000;
      display: flex;
      align-items: start;
      justify-content: start;
  }
    .achieve-more #runner{
      width: 500px;
  }
  
  .main-head{
      display: flex !important;
      align-items: start;
      justify-content: start;
      padding: 10px 0 !important;
  }
  .main h1{
      font-size: 100px !important;
      font-weight: 500;
      text-align: left;
      font-family: 'Bebas Neue', sans-serif;
      line-height: 90px;
      color: #fff;
  }
  .main-head::after{
     display: none;
    }
   
}


@media (max-width:999px){

  .top-header .navbar a img{
      width: 300px;
      
   }
  .sidebar {
      position: fixed;
      width: 100% !important;
      height: 100vh;
      top: -1000px;
      left: 0;
      right: 0 !important;
      float: right;
      /* transform: translateY(-50%); */
      list-style: none;
      border-left: none;
      padding: 25px ;
      background-color: #a00dec;
      
      z-index: 9999;
      text-align: center;
      /* margin-top: 30px; */
      
  }
  .sidebar.active{
      top: 50%;
      transition: 0.6s;
  }
 
  .sidebar li {
      margin-bottom: 0px;
      padding: 15px 0;
      display: grid;
      place-items: center;

      z-index: 1;
  }
  .sidebar li a {
      color: #fff;
      text-decoration: none;
      font-size: 1.5rem;
      font-family: 'Aileron', sans-serif;
      /* display: none; */
      
  }
  .sidebar li:hover{
     background-color: #fff;
     text-align: center;
     border-radius: 20px;
     
  }
  .sidebar li a:hover{
      color: #a00dec;
  }
  .btn-close{
      display: block;
      margin-top: 40px;
     
    }
    .hide{
      display: none;
      transition: 0.6s;
  }
  .cont_slider{
      width: 400px;
      padding-right: 10px;
      margin-left: -50%   !important;
    }

} 
@media (max-width:770px){

  .col2-about{
      width: 100%;
      display: block;
      align-items: end;
      justify-content: left

    }
    .cont_slider{
      width: 400px;
      margin-left: -20%   !important;
    }
    .second{
      width: 100%;
      /* height: 80vh; */
      background-color: #000000;
      display: flex;
      align-items: start;
      justify-content: start;
  }
  /* .achieve-more{
      margin-left: -15%;
  } */
    .achieve-more #runner{
      width: 400px;
  }
  
  .main-head{
      display: flex !important;
      align-items: start;
      justify-content: start;
      padding: 10px 0 !important;
  }
  .main h1{
      font-size: 80px !important;
      font-weight: 500;
      text-align: left;
      font-family: 'Bebas Neue', sans-serif;
      line-height: 75px;
      color: #fff;
  }
}
@media (max-width:600px){
  .second{
    height: auto !important;
  }
.cont_slider .swiper-slide{
  padding: 0;
}
.text h5{
  font-size: 20px;
}
.BtnSection{
  padding: 16px 8px;
}
}
@media (max-width:550px){
  .top-header .navbar a img{
      width: 200px;
      
   }
   .fa-bars{
      color: #fff;
      font-size: 30px;
      cursor: pointer;
  }
  .col2-about{
      width: 50%;
      display: block;
      align-items: start;
      justify-content: left;
    }
    .cont_slider{
      width: 300px;
      margin-left: -45%   !important;
    }
    .second{
      width: 100%;
      height: 100vh;
      background-color: #000000;
      display: flex;
      align-items: start;
      justify-content: start;
  }
  
      .achieve-more{
          width: 100%;
          /* margin-left: -35%; */
      }
    .achieve-more #runner{
      width:300px;
  }
  .main h1{
      font-size: 70px !important;
      font-weight: 500;
      text-align: left;
      font-family: 'Bebas Neue', sans-serif;
      line-height: 65px;
      color: #fff;
  }
  .text h5{
      text-align: center;
    }
    .cust1 p{
      max-width: 300px;
      font-weight: 200;
      font-size: 20px;
      margin-top: 30px;
      color: #fff;
      text-align: center;
  }
 
}

@media (max-width:480px){
  .top-header{
      width: 100vw;
      /* background-color: #000013; */
      padding: 15px 20px 0; 
      position: fixed;
      z-index: 9999;
  }
  .content h1 {
      font-size: 3rem;
      font-weight: 500;
      text-align: left;
      font-family: 'Bebas Neue', sans-serif;
      line-height: 40px;
      margin-left: 2%;
  }
  .achieve-more{
      width: 100%;
      margin-left: -15%;
      flex-wrap: nowrap;
  }
  .achieve-more #runner{
      width:250px;
  }
  .main h1{
      font-size: 60px !important;
      font-weight: 500;
      text-align: left;
      font-family: 'Bebas Neue', sans-serif;
      line-height: 55px;
      color: #fff;
  }
  
  .cont_slider{
      width: 300px;
      padding-right: 10px;
      margin-left: 0;
    }
    .text h5{
      margin-left: 0;
      font-size: 20px ;
    }
    .cont_slider .swiper-slide{
      padding: 0;
    }
}
@media (max-width:400px){
  .main h1{
    font-size: 48px !important;
    line-height: 40px;
  }
  .cont_slider{
    width: auto;
    margin-left: -100px !important;
  }
}




@media (max-width:1145px) {
  footer{
      width: 100%;
      display: grid;
      place-items: center;
    }
    .links{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
     
    }
    .footer-menu li{
      padding:  10px;
      text-align: center;
    }
    .menulist-social{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px 0;
      flex-wrap: wrap;
    }
}