
  
  .open-btn {
    position: fixed;
    bottom: 20px;
    right: 130px;
    border: none;
    border-radius: 10px;
     background: transparent;
    cursor: pointer;
   
    z-index: 22;
  }
  
  .open-whatsapp-icon {
    width: 50px;
    height: 50px;
  }
  

  