
.about2{
    width: 100%;
    padding: 30px;
  }
  .future2{
    width: 100%;
  }
  .future2 h5{
    color: #a50fed;
    font-weight: 500;
    font-family: 'Aileron-Bold';
  }
  .future2 p{
    width: 100%;
    /* max-width: 1000px; */
    color: #fff;
    text-align: justify;
    font-weight: 400 !important;
    font-family: 'Aileron-Regular';
  }
  .available2{
    background: linear-gradient(to top,  #7d02d9 8%, #a50fed 50% );
    width: 100% !important;
    padding: 20px 30px!important;
    color: #fff;
    margin-top: 15px !important;
  }
  .available2 h6{
    font-size: 20px;
    font-family: 'Aileron-Bold';
  }
  .available2 p{
    font-weight: 400 !important;
    font-family: 'Aileron-Regular';
    /* margin-top: 10px !important; */
  }
  @media (min-width:200px) and (max-width:580px){
    
    .available{

      width: 100% !important;
      padding: 20px 30px!important;
      color: #fff;
      margin-top: 15px !important;
    
    }
  }