
.blog{
    width: 100%;
    padding: 30px;
  }
  /* .blog_content{
    width: 100%;
  } */
  .blog_content h5{
    color: #a50fed;
    font-weight: 500;
    font-family: 'Aileron-Bold';
  }
  .blog_content p{
    width: 100%;
    /* max-width: 1000px; */
    color: #fff;
    text-align: justify;
    font-weight: 400 !important;
    padding-bottom: 20px;
    font-family: 'Aileron-Regular';
  }
  .blogs_row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 50px 0;
  }
  .blog_col{
    width: 400px;
    margin: 15px 010px;
  }
  .blogImages{
    position: relative;
  }
  .blogImages img{
    width: 100%;
    object-fit: cover;
  }
  .icons_1{
    position: absolute;
    left: 10px;
    bottom: 10px;
  }
  .icons_1 img{
    width: 40px;
  }
  .content_field{
    color: #fff;
    padding:15px 20px 0 10px;
    /* max-width: 350px; */
   
  
  }
  .content_field h5{
    font-family: 'Aileron-Bold';
  }
  .content_field p{
    color: #b0b0b0;
    font-family: 'Aileron-Regular';
  }
  .view_more{
    background-color: transparent ;
    border: 1px solid #fff;
    color: #fff;
    padding: 7px 15px;
    text-transform: uppercase;
    font-size: 1;
    font-size: 13px;
  }
  @media screen and (max-width:1240px){
    
.blogs_row{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 50px 0;
  }

  
  }  

  @media screen and (max-width:999px){
    .blog_col{
      border: 1px solid #383838;
      padding-bottom: 10px;
    }
   
  }

  @media screen and (max-width: 768px) {
    .blog_content h5, 
    .blog_content p{
        padding:0 20px ;
      }
      .content_field p{
        padding: 0;
      }
  }