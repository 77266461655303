
.countdown{
  display: grid !important;
  place-items:center !important;
  text-align:center !important;
  width: 70% !important;
}
.countdown h2{
  font-family: 'Aileron-Bold';
  color:#efb800;
  max-width: 500px;
  text-align: center;
}
#clockdiv{
  font-family: 'Aileron-Bold';
  color: #a710e9;
	display: inline-block;
	font-weight: 100;
	text-align: center;
	font-size: 25px;
}

#clockdiv > div{
	padding: 8px;
	border-radius: 3px;
	/* background: #00BF96; */
	display: inline-block;
 
}



#clockdiv div > span{
  width: 50px;
	padding: 8px;
	border-radius: 3px;
	background: #ffffff;
	display: inline-block;
  font-family: 'Aileron-Bold';
  box-shadow: 0 0 15px #7b0fb1;
}

.smalltext{
	padding-top: 5px;
	font-size: 16px;
  color: #fff;
  font-family: 'Aileron-Bold';
}
@media (min-width:924px) and (max-width:1240px){
  .countdown{
    margin-left: 268px;
  }
}
@media (min-width:768px) and (max-width:924px){
  .countdown{
    margin-left: 227px;
  }
}

@media (max-width: 768px){
  
.countdown{
 
  width: 100% !important;
}
}