.section6{
    width: 100%;
  
    margin-top: 7%;
}
.banner6{
    top: 0;
    width: 100%;
    height: 90vh;
    background-image: url("../../../public/assets/images/cloudsecurity-cover.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    position: relative;
}

.main-content6{
  width: 100% !important;
  display: flex;
  align-items: center;
  padding: 0 50px;
  /* background-color: yellow; */
}

.side-bar6{
  display:grid;
  place-items: center;

}
.linebar1{
  height: 200px;
  width: 6px;
  background: linear-gradient(to bottom, #000,#a50fed);
}
.circle6{
  width: 20px;
  height: 20px;
  background-color: #a50fed;
  border-radius: 50px;
  margin: 5px 0;
  display: grid;
  place-items: center;
}
.black-circle6{
  width: 15px;
  height: 15px;
  border: 3px solid #000;
  border-radius: 50px;

}
.linebar2{
  height: 200px;
  width: 6px;
  background: linear-gradient(to top, #000,#a50fed);
}


.content6 {
    text-align: left;
    margin-left: 5%;
    width: 100%;
    
}
.content6 h1 {
  font-size: 2.5rem;
    font-weight: 500;
    text-align: left;
   font-family: 'BebasNeue-Regular';
    /* line-height: 50px; */
    color: #ffffff;
}
.content6 h5 {
    font-weight: 500;
    /* line-height: 58px; */
    color: #a50fed;
    font-family: 'Aileron-Bold';
}
.content6 p {
  color: #ffffff;
  width: 100%;
  max-width: 400px !important;
  font-family: 'Aileron-Light';
}
.list6{
  padding: 0 !important;
}
.list6 li{
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: left;
  color: #ffff;
  font-family: 'Aileron-Light';
}
.list6 li img{
  width: 20px;
}
.heroForm6 form{
  margin-left: auto;
}

.form6{
  background: linear-gradient(to top, #7d02d9 8%,#a50fed 40% );
  width: 400px;
  margin-right: 6%;
  padding: 40px 20px;
  border-radius: 30px;

}
.form6 h6{
  text-align: center;
  color: #fff;
  padding: 8px 0;
  font-family: 'Aileron-Bold';
}
.input-box6{
  position: relative;
  width: 100%;
  margin: 10px 0;
  display: flex;
  align-items: center;
  border: 1px solid #b4b0b0;
}
.input-box6 img{
  margin-right: 10px;
}

.input-box6 input{
  width: 100%;
  padding: 8px 30px;
  border: none !important;
  background-color: transparent;
  color: #fff !important;
  font-size: 14px;
  outline: none;
  flex: 1;
}
textarea{
  border-radius: 20px;
}
.input-box6 span{
  position: absolute;
  left: 0;
  padding: 12px 30px;
  color: #ffffff;
  text-transform: uppercase;
  pointer-events: none;
  font-size: 13px;
  transition: 0.5s;
  font-family: 'Aileron-UltraLight';
}

.input-box6 input:valid ~ span,
.input-box6 input:focus ~ span
{
  color: #f8f8f8 !important;
  transform: translateX(20px) translatey(-13px);
  font-size: 0.6em;
  padding: 0 10px;
  font-weight: 500;
}
/* chrome*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button{
  -webkit-appearance: none;
}

/*firefox*/
/* input[type="number"]{
  -moz-appearance: textfield;
} */
.button-sec6{
  width: 100% !important;
  display: grid !important;
  place-items: center !important;
  top: 0 !important;
}
.submit6{
  width: 100%;
  background-color: transparent !important;
  border: 2px solid #fff !important;
  color: #ffffff !important;
  padding: 10px 50px !important;
  border-radius: 50px !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  margin-top: 0 !important;
  font-family: 'Aileron-Bold';
}
.submit6:hover{
  border: 1px solid #c277e4 !important;
}

.bar6{
  width: 650px;
  height: 8px;
  background-color: #a50fed;
  bottom: 0;
  position: absolute;
}

#phone-number{
  margin-left: 40px;
}

.flag6{
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-right: 1px solid #ffff;
}
.flag6 p{
  color: #fff;
  padding-top: 14px;
}

@media screen and (max-width:1240px){
    .banner6{
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url("../../../public/assets/images/cloudsecurity-cover.jpg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        position: relative;
    }
   
       .content6 {
          position: relative;
          margin-left: 8%;
          width: 100%;
          /* margin-top: 6%; */
      }
      .content6 h1 {
          font-size: 51.2px;
          font-weight: 500;
          text-align: left;
          /* font-family: 'Bebas Neue', sans-serif; */
          line-height: 58px;
          color: #ffffff;
      }
      .best-ser6{
        margin-bottom: 0 !important;
        display: grid !important;
        place-items: center !important;
        flex-wrap: wrap !important;
        }
      
}

@media (min-width:769px) and (max-width:1150px){
    .content6 {
        position: relative;
        margin-left: 8%;
        width: 100%;
        height: auto;
        margin-top: 10%;
    }
      
    .side-bar6{
      display:grid;
      place-items: center;
      margin-top: 10%;
    }
    
    .heroForm6 .form6{
      margin-left: -45px;
      margin-top: 35%;
    }
    
    .phoneicon{
      position: absolute;
      top: 0;
      right: -5px;
    }
   
    .form6{
      /* background: linear-gradient(to top, #7d02d9,#a50fed ); */
      width: 100%;
      max-width: 400px;
      margin-right: 16%;
      padding: 40px 20px;
      border-radius: 30px;
    }
    .input-box6 input{
      width: 100%;
      padding: 8px 30px;
      border: none !important;
      background-color: transparent;
      color: #fff !important;
      outline: none;
      flex: 1;
    }
}

@media screen and (max-width: 768px) {
    .banner6{
      display: block;
      width: 100%;
      height: 100%;
      background-image: url("../../../public/assets/images/cloudsecurity-cover.jpg");
      background-position: 55% 20%;
      background-repeat: no-repeat;
      align-items: center;
      position: relative;
      padding-bottom: 28px;
    }
    .main-content6 {
      width: 100% !important;
      display: flex;
      align-items: center;
     padding: 0;
  }
  .content6 {
    position: relative;
    margin-left: 0;
    width: 100%;
    height: auto;
    margin-top: 35px;
  }
  .heroForm6{
  padding: 25px;
  }
  .form6{
  width: 100%;
  margin-left: 0;
  }
  }

  @media (min-width:200px) and (max-width:580px){
    .content6 h1 {
      font-size: 38px;
        font-weight: 500;
        text-align: left;
        /* font-family: 'Bebas Neue', sans-serif; */
        line-height: 35px;
        color: #ffffff;
    }

    .banner6{
        display: block;
        width: 100%;
        height: 100%;
        background-image: url("../../../public/assets/images/cloudsecurity-cover.jpg");
        background-position: 55% 20%;
        /* background-size: contain; */
        background-repeat: no-repeat;
        align-items: center;
        position: relative;
        padding-bottom: 28px;
      }
      .main-content6 {
        width: 100% !important;
        display: flex;
        align-items: center;
       padding: 0;
    }
    /* .content6 {
      position: relative;
      margin-left: 0;
      width: 100%;
      height: auto;
      margin-top: 115px;
  } */
  .heroForm6{
    padding: 25px;
  }
  .form6{
    width: 100%;
    margin-left: 0;
  }

  }

  @media  (max-width:385px){
    .content6 h1 {
      font-size: 28.2px;
      font-weight: 500;
      text-align: left;
      /* font-family: 'Bebas Neue', sans-serif; */
      line-height: 35px;
      color: #ffffff;
  }

  .content6 h5{
    font-size: 17.2px;
    font-weight: 400;
  }
  }