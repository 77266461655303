.row1_services{
  width: 100%;
  max-width: 1200px;
} 
.cloud-servicesContainer{
    width: 100%;
    text-align: center;
    margin: 30px 0;
    display: grid;
    place-items: center;
    padding: 0 30px; 
    /* background-color: black;  */
  }
  .services8{
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
    flex-wrap: wrap;
    gap: 50px;
    margin: 30px 0;
  }
  .service-content8{
    text-align: left;
    color: #ffff;
    max-width: 500px;
    width: 100%;
    font-size: 'Aileron-Bold';
  }
  .service-content8 h5{
    font-family: 'Aileron-Bold';
    font-size: 25px;
  }
  .service-content8 p{
    font-size: 14px;
    text-align: justify;
    /* color: #9d9d9d; */
    margin-top: 20px;
    font-family: 'Aileron-UltraLight';
    /* word-spacing: -2px; */
  }
  .align8 {
    display: flex;
    align-items: end;
  }
  .image8{
    display: flex;
    align-items: end;
  }
  .image8 img{
    width: 500px;
  }
  .left-bar{
    content: "";
    width: 15px;
    height: 130px;
    background-color: #960dd9;
    bottom: 0;
  }
  
  
  .contact-btn8{
    margin: 30px 0;
  }
  .contact8{
   border-radius: 50px !important;
    background-color: transparent !important;
    border: 1px solid #a50fed !important;
    box-shadow: none !important;
    outline: none !important;
    padding: 8px 80px !important;
    font-size: 15px !important;
    color: #a50fed !important;
    font-family: 'Aileron-Bold';
  }
  .contact8:hover{
    border: 1px solid #bb55ea !important;
    font-weight: 500 !important;
  }

  @media screen and (max-width:825px){
    .service-content8{
        text-align: left;
        color: #ffff;
        max-width: 100%;
        width: 100%;
        padding: 10px;
      }
      .services8{
        width: 100% !important;
        display: flex !important;
        justify-content: left !important;
        flex-wrap: wrap;
        gap: 10px;
        margin: 30px 0;
        border: 1px solid #767474;
      }
      .image8 img{
        width: 100%;
      }
  }
  @media screen and (max-width:1240px){


    .row1_services{
      width: 100%;
      max-width: 1200px;
      display: grid;
      place-items: center;
    }
    /* .image8 img{
      width: 300px;
    } */
  
  
  }
  @media screen and (max-width:1024px){
      .services8{
      width: 100% !important;
      display: grid !important;
      place-items: center;
      flex-wrap: wrap;
      gap: 50px;
      margin: 30px 0;
  
  
    }
 
    .service-content8 p{
      font-size: 14px;
      text-align: left;
      color: #9d9d9d;
    
    }
  }

  @media screen and (max-width:768px){
    .left-bar{
      display: none;
    }
  }
  @media screen and (max-width:600px){
    .service-content8 p{
      font-size: 14px;
      text-align: justify;
      color: #9d9d9d;
    
    }
  }



  