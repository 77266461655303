.about_home{
    width: 100%;
    padding: 30px;
    background-color: #000013 !important;
  }
  .MvContainer h2{
    color: aliceblue;
    margin-right: 30px;
  }
  .about_ST{
    width: 100%;
  }
  .about_ST h5{
    font-size: 28px;
    color: #a50fed;
    font-weight: 500;
    font-family: 'Aileron-Bold';
  }
  .about_ST p{
    width: 100%;
    /* max-width: 1000px; */
    color: #fff;
    text-align: justify;
    font-weight: 200 !important;
    padding-bottom: 20px;
    font-family: 'Aileron-Light';
  }
  .ST_story h6{
    color: #fff;
    font-size: 23px;
    font-family: 'Aileron-Light';
  }
  .ST_story p{
    padding-top: 10px;
    font-family: 'Aileron-Light';
  }
  .MvContainer{
    background-color: #000;
    border: 1px solid #373739;
    margin:  8px !important;
  }
  .MV_COntent{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }
  .mv_img img{
    width: 120px;
  }
  .MV_COntent p{
    /* word-spacing: .8px; */
    font-size: 14px;
    font-family: 'Aileron-Light';

  }
  
  
  .define h4{
    text-align: left;
    color: #fff;
    font-family: 'Aileron-Bold';
    }

  /* .aboutSection{
    margin: 30px 0 40px;
  } */
  
  .about_theme{
    width: 100% !important;
   
  }
  .about_our{
    width: 100%;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;
    margin: 25px 0;
    padding:0 15px;
  }
  /* .image{
  
  } */
  .decribe_img {
    width: 250px;
    height: 180px;
    border-top-right-radius: 70px;
    border-bottom-left-radius: 70px;
    background-color: #000;
    position: relative;
    overflow: hidden; /* Ensure the content doesn't overflow the rounded corners */
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); /* Default square clip-path */
  }
  .decribe_img img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    mix-blend-mode: screen;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); /* Same default square clip-path */
  }
  .col_about{
    width: 100%;
    max-width: 950px;
    color: #fff;
    border-bottom: 1px solid #373739;
    margin-top: 20px;
  }
  .col_about h5{
    font-weight: 400;
    font-size: 28px;
    font-family: 'Aileron-Regular';
  }
  .col_about p{
    text-align: justify;
    font-size: 14px;
    font-family: 'Aileron-Regular';
  }
  @media screen and (max-width:992px){
    .MV_COntent{
      place-items: center;
      gap: 15px;
    }
  }
  
  @media (min-width:768px) and (max-width:992px){
    .MV_COntent{
      display: block !important;;
      place-items: center;
      gap: 15px;
    }
    .MV_COntent p{
    word-spacing: .8px;
    font-size: 13px;
    }
  }
  @media screen and (max-width:999px){
    .rowMissionVision{
      display: grid !important;
      place-items: center !important;
    }
  
     .define p{
      text-align: left;
     }
  }
  @media screen and (max-width:500px){
    .MV_COntent{
      display: grid;
      place-items: center;
      gap: 15px;
    } 
  }
