#loading{
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: black !important;
  display: grid;
  place-items: center;
  z-index: 9999999;
  top: 0;
  left: 0;
}
#sahara{
  position: absolute;
  font-family: 'Eurostile LT Pro Unicode';
  color: white;
  font-size: 60px;
  font-weight: 700;
}
#preloader{
  animation: loading 2s linear infinite;
}

@keyframes loading{
  0%, 100%{ 
    opacity: 0.2;
    transform: scale(0) rotate(0deg);
  }
  
  50%{ 
    opacity: 1;
    transform: scale(1) rotate(180deg);
  }
}
