.contact_icon{
  background-color: transparent;
  border: none;
}
.contact_icon img{
  width: 30px;
}
#logo{
  width: 50%;
}
.top-header2{
    background-color: #000013;
      width: 100vw !important;
      padding: 15px 50px 0; 
      position: fixed;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      top: 0;
      border-bottom: 1px solid #3e3e3e;
      /* background-color: yellow; */
  }
  
  /* .logo-align{
      text-align: left;
  } */
  /* .nav{
    text-align: left;
      
  } */
  .nav-item2 a{
      color: #fff !important;
      text-align: left;
      font-family: 'Aileron-Bold';
  }
  .nav-item2 a:hover{
      color: #a00dec !important;
  }
  
  .navbar-toggler{
    display: none;
  }
  
  .fa-bars{
      color: #fff;
      font-size: 30px;
  }
  #btn-close{
    display: none;
  }
  .close-btn{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right !important;
  }
  .btn-close{
    outline: none !important;
    box-shadow: none !important;
    width: 30px !important;
    background-color: #fff !important;
    padding:8px 5px !important;
  }
  /* .hide{
    display: none;
  } */
  /* button{
    background-color: #;
  } */
  @media (max-width: 1240px) {

    .custNav{
        display: flex;
        align-items: center;
        gap: 20px;
    }
    .top-header2 .navbar a img{
      width: 200px;
      
   }
  
   .navbar-toggler{
      display: block;
    }
   .nav{
      width: 100%;
      height: 100vh;
      background: linear-gradient(to top, #7d02d9,#a50fed );
      display: block !important;
      /* place-items: center; */
      position: absolute;
      /* top: 80% !important; */
      z-index: 9999;
      top: 0;
      padding: 30px;
      right: -1500px;
    transition: 0.4s;
   }
   .active{
      right: 0;
    }
     .nav-item2{
      margin-left: 15px;
    }
    #btn-close{
      display: block;
    }
    
   .nav-item2 a{
      color: #fff !important;
      text-align: center;
      font-size: 25px;
      padding: 15px 0;
      font-weight: 600;
      /* position: absolute; */
  }
  .nav-item2:hover{
      width: 100% !important;
      background-color: #fff;
      color: #a50fed;
      border-radius: 20px;
  }


  }
  @media (min-width:200px) and (max-width:999px){

}

@media  (max-width:999px){
  .contact_icon img{
    width: 32px;
    top: 43px;
    right: 101px;
    position: absolute;
  }
}
@media  (max-width:575px){
  #logo{
    width: 60%;
  }
}

@media (max-width: 475px) {
  .contact_icon img {
      width: 32px;
      top: 37px;
      right: 101px;
      position: absolute;
  }
}