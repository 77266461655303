

.hometimer{
  display: flex !important;
  align-items: center !important;
}

@media (max-width: 418px) {
  .phoneicon {    
      position: absolute;
      right: -3px;
      top: 2px;
  }
}
.sectionOdo{
    width: 100%;
  
    margin-top: 7%;
}
.bannerOdo{
    top: 0;
    width: 100%;
    height: 90vh;
    background-image: url("../../../../public/assets/images/odoo_coverImg.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    position: relative;
}
/* .Odoform{
  width: 100% !important;
} */
.main-contentOdo{
  width: 100% !important;
  display: flex;
  align-items: center;
  padding: 0 50px;
  /* background-color: yellow; */
}

.side-barOdo{
  display:grid;
  place-items: center;

}
.lineOdo{
  height: 200px;
  width: 6px;
  background: linear-gradient(to bottom, #000,#a50fed);
}
.circleOdo{
  width: 20px;
  height: 20px;
  background-color: #a50fed;
  border-radius: 50px;
  margin: 5px 0;
  display: grid;
  place-items: center;
}
.black-circleOdo{
  width: 15px;
  height: 15px;
  border: 3px solid #000;
  border-radius: 50px;

}
.lineOdo1{
    height: 200px;
    width: 6px;
    background: linear-gradient(to bottom, #000,#a50fed);
}
.lineOdo{
  height: 200px;
  width: 6px;
  background: linear-gradient(to top, #000,#a50fed);
}

.odoo-official{
  padding:0  0 10px;
}
.odoo-official img{
  width: 180px;
}
.contentOdo {
    text-align: left;
    margin-left: 5%;
    width: 100%;
    
}
.contentOdo h1 {
  font-size: 37px;
    font-weight: 500;
    text-align: left;
    font-family: 'BebasNeue-Regular';
    /* line-height: 50px; */
    color: #ffffff;
}
.contentOdo h5 {
    font-weight: 500;
    /* line-height: 58px; */
    color: #a50fed;
    font-family: 'Aileron-Bold';
}
.contentOdo p {
  color: #ffffff;
  width: 100%;
  max-width: 400px !important;
  font-family: 'Aileron-Light';
}
.listOdo{
  padding: 20px 0 !important;
}
.listOdo li{
  display: flex;
  align-items: center;
  gap: 10px;
  text-align: left;
  color: #ffff;
  font-size: 14px;
  padding: 3px 0;
  line-height: 16px;
  font-family: 'Aileron-Light';
}
.listOdo li img{
  width: 20px;
}

.services_listOdo{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.services_listOdo li{
  display: grid;
  place-items: center;
  padding: 10px ;
  color: #fff;
  margin: 0 20px;
  font-family: 'Aileron-Bold';
}
.services_listOdo li img{
  width: 25px;
}

/*form*/
.heroFormOdo form{
  margin-left: auto;
}

.formOdo{
  background: linear-gradient(to top, #7d02d9,#a50fed );
  width: 400px;
  margin-right: 6%;
  padding: 40px 20px;
  border-radius: 30px;

}
.formOdo h6{
  text-align: center;
  color: #fff;
  padding: 8px 0;
  font-weight: 900;
  font-family: 'Aileron-Bold';
}
.input-boxOdo{
  position: relative;
  width: 100%;
  margin: 10px 0;
  display: flex;
  align-items: center;
  border: 1px solid #b4b0b0;
}
.input-boxOdo img{
  margin-right: 10px;
}

.input-boxOdo input{
  width: 100%;
  padding: 8px 30px;
  border: none !important;
  background-color: transparent;
  color: #fff !important;
  font-size: 14px;
  outline: none;
  flex: 1;
}
textarea{
  border-radius: 20px;
}
.input-boxOdo span{
  position: absolute;
  left: 0;
  padding: 12px 30px;
  color: #ffffff;
  text-transform: uppercase;
  pointer-events: none;
  font-size: 13px;
  transition: 0.5s;
  font-family: 'Aileron-UltraLight';
}

.input-boxOdo input:valid ~ span,
.input-boxOdo input:focus ~ span
{
  color: #f8f8f8 !important;
  transform: translateX(20px) translatey(-13px);
  font-size: 0.6em;
  padding: 0 10px;
  font-weight: 500;
}
/* chrome*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button{
  -webkit-appearance: none;
}

/*firefox*/
/* input[type="number"]{
  -moz-appearance: textfield;
} */
.button-secOdo{
  width: 100% !important;
  display: grid !important;
  place-items: center !important;
  top: 0 !important;
}
.submitOdo{
  width: 100%;
  background-color: transparent !important;
  border: 2px solid #fff !important;
  color: #ffffff !important;
  padding: 10px 50px !important;
  border-radius: 50px !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  margin-top: 0 !important;
  text-transform: capitalize;
  font-family: 'Aileron-Bold';
}
.submitOdo:hover{
  border: 1px solid #c277e4 !important;
}
.flagOdo p{
  font-family: 'Aileron-UltraLight';
}

/* .bar{
  width: 600px;
  height: 8px;
  background-color: #a50fed;
  bottom: 0;
  position: absolute;
} */

#phone-number9{
  margin-left: 40px;
}

.flagOdo{
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-right: 1px solid #ffff;
}
.flagOdo p{
  color: #fff;
  padding-top: 14px;
}


@media (min-width:769px) and (max-width:1150px){
  .heroFormOdo .formOdo{
    margin-left: -45px;
    margin-top: 35%;
  }
  
  .formOdo{
    background: linear-gradient(to top, #7d02d9,#a50fed );
    width: 100%;
    max-width: 400px;
    margin-right: 40%;
    padding: 40px 20px;
    border-radius: 30px;
  }

  .phoneicon{
    position: absolute;
    top: 0;
    right: -5px;
  }

}

@media screen and (max-width:1240px){
  .hometimer{
    display: block !important;
  }
  .bannerOdo{
    top: 0;
    width: 100%;
    height: 100%;
    /* background-image: url(../images/odoo_coverImg.jpg); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    position: relative;
}

   .contentOdo {
      position: relative;
      margin-left: 8%;
      width: 100%;
      /* margin-top: 6%; */
  }
  .contentOdo h1 {
      font-size: 40px;
      font-weight: 500;
      text-align: left;
      /* font-family: 'Bebas Neue', sans-serif; */
      line-height: 39px;
      color: #ffffff;
  }

 
}


@media (min-width:769px) and (max-width:1150px){
  .contentOdo{
    position: relative;
    margin-left: 8%;
    width: 100%;
    height: auto;
    margin-top: 10%;
}
  
.side-barOdo{
  display:grid;
  place-items: center;
  margin-top: 10%;
}

.heroFormOdo form{
  margin-left: auto;
  margin-top: 35%;
}

.formOdo{
  background: linear-gradient(to top, #7d02d9,#a50fed );
  width: 100%;
  max-width: 400px;
  margin-right: 16%;
  padding: 40px 20px;
  border-radius: 30px;
}
.input-boxOdo input{
  width: 100%;
  padding: 8px 30px;
  border: none !important;
  background-color: transparent;
  color: #fff !important;
  outline: none;
  flex: 1;
}
}

@media screen and (max-width: 768px) {
  .bannerOdo{
    display: block;
    width: 100%;
    height: 100%;
    /* background-image: url(../images/odoo_coverImg.jpg); */
    background-position: 55% 20%;
    background-repeat: no-repeat;
    align-items: center;
    position: relative;
    padding-bottom: 28px;
  }
  .main-contentOdo {
    width: 100% !important;
    display: flex;
    align-items: center;
   padding: 0;
}
.contentOdo {
  position: relative;
  margin-left: 0;
  width: 100%;
  height: auto;
  margin-top: 115px;
}
.heroFormOdo{
padding: 25px;
}
/* .formOdo{
width: 100%;
margin-left: 0;
} */
}
@media (min-width:581px) and (max-width:768px){
  .heroFormOdo form{
   margin-left: 159px;
  }
}

@media  (max-width:580px){
  .contentOdo h1 {
    font-size: 36px;
    font-weight: 400;
    text-align: left;
    /* font-family: 'Bebas Neue', sans-serif; */
    line-height: 41px;
    color: #ffffff;
}


  .bannerOdo{
    display: block;
    width: 100%;
    height: 100%;
    /* background-image: url(../images/odoo_coverImg.jpg); */
    background-position: 55% 20%;
    background-repeat: no-repeat;
    align-items: center;
    position: relative;
    padding-bottom: 28px;
  }
  .main-contentOdo {
    width: 100% !important;
    display: flex;
    align-items: center;
   padding: 0;
}
.contentOdo {
  position: relative;
  margin-left: 0;
  width: 100%;
  height: auto;
  margin-top: 115px;
}
.heroFormOdo{
padding: 25px;
}
.formOdo{
width: 100%;
margin-left: 0;
}
}

@media  (max-width:457px){
  .contentOdo h1 {
    font-size: 30px;
    font-weight: 400;
    text-align: left;
    /* font-family: 'Bebas Neue', sans-serif; */
    line-height: 25px;
    color: #ffffff;
}
.contentOdo p {
  font-size: 15px;
  color: #ffffff;
  width: 100%;
  max-width: 400px !important;
}
.contentOdo h5 {
  font-weight: 500;
  
  font-size: 17px;
  /* line-height: 58px; */
  color: #a50fed;
}
}

