.section7{
    width: 100%;

    margin-top: 7%;
}
.banner7{
    top: 0;
    width: 100%;
    height: 40vh;
    background-image: url("../../../public/assets/images/solution-cover.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    align-items: center;
    position: relative;
}

.content7 {
    text-align: center;
    margin-left: 8%;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40vh;
    border-bottom: 1px solid #3e3e3e;
    /* margin-top: 6%; */
}
.content7 h1 {
    font-size: 3.5rem;
    font-weight: 500;
    text-align: left;
    font-family: 'BebasNeue-Regular',;
    line-height: 58px;
    color: #ffffff;
}
.content7 h1 span{
    font-size: 2.5rem;
}

.bar7{
  width: 650px;
  height: 8px;
  background-color: #a50fed;
  bottom: 0;
  position: absolute;
}
@media (min-width:200px) and (max-width:1165px){
    .content7 {
        position: relative;
        
        text-align: center;
        margin-left: 8%;
        display: flex;
        align-items: center;
        width: 100%;
        height: 40vh;
        margin-top: 1%;
    }
    .content7 h1 {
        font-size: 3.5rem;
        font-weight: 500;
        text-align: left;
        /* font-family: 'Bebas Neue', sans-serif; */
        line-height: 58px;
        color: #ffffff;
    }

    .section7{
        width: 100%;
       
        margin-top: 10%;
    }
}

@media  (max-width:580px){
    .content7 h1 {
        font-size: 37px;
        font-weight: 400;
        text-align: left;
        /* font-family: 'Bebas Neue', sans-serif; */
        line-height: 41px;
        color: #ffffff;
    }
    /* .section7 {
        width: 100%;
        background-color: rgb(75, 75, 39);
        margin-top: 14%;
    } */
}
@media  (max-width:423px){
    .content7 h1 {
        font-size: 31px;
        font-weight: 400;
        text-align: left;
        /* font-family: 'Bebas Neue', sans-serif; */
        line-height: 35px;
        color: #ffffff;
    }

    .banner7{
        top: 0;
        width: 100%;
        height: 40vh;
        background-image: url("../../../public/assets/images/solution-cover.jpg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        display: block;
        align-items: center;
        position: relative;
    }
}
@media screen and (max-width:600px){
    .banner7{
        top: 0;
        width: 100%;
        height: 40vh;
        background-image: url("../../../public/assets/images/solution-cover.jpg");
        background-position: right;
        /* background-size: cover; */
        background-repeat: no-repeat;
        display: block;
        align-items: center;
        position: relative;
    }
    .section7{
        width: 100%;
    
        margin-top: 10%;
    }
}