.sliderbotton {
  margin-left: auto;
}

.sqaure-img{
    width: 100%;
}
.sqaure-img img{
    width: 320px;
}
.your_success{
    width: 100%;

    display: grid !important;
    place-items: center !important;
    color: #fff;
    text-align: center;
    padding: 20px;
}
.your_success h5{
    font-size: 23px;
    font-family: 'Aileron-Bold';
}
.your_success p{
    max-width: 780px;
    font-size: 15px;
    font-weight: 200;
    letter-spacing: 0.5px;
    font-family: 'Aileron-UltraLight';
}

@keyframes zoomIn {
    from {
      transform: scale(0);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
/*   
  .mission {
    display: inline-block; 
    animation: zoomIn 1s ease-out forwards;
  } */
  
  
.service{
    margin: 50px 0;
    /* background-color: #000000 !important; */
    text-align: center;
    display: grid;
    place-items: center;
    font-family: 'Aileron';
    /* background-image: url(../images/bg.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: auto; */

}
.service-sec{
    width: 100%;
    display: grid;
   place-items: center;
}
.mission{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Aileron';
}
.mission h6{
    font-family: 'Aileron-Bold';
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    color: #fff;
}
.mission h1{
    font-size: 9rem;
    font-weight: 200;
    color: #fff;
    font-family: 'Aileron-UltraLight';
}

.service-content{  
    max-width: 650px;
    display: grid;
    place-items: center;
    color: #fff;
}
.service-content h3{
    font-size: 38px;
    font-weight: 100;
    font-family: 'Aileron-UltraLight';
}
.service-content h5{
    margin-top: 8px;
}
.service-content p{
    font-size: 11px;
    font-weight: 200;
    letter-spacing: 0.5px;
}
@media (max-width: 768px) {
    .service-content h3 {
        font-size: 25px;
        font-weight: 100;
    }
    .mission h1 {
        font-size: 7rem;
        font-weight: 200;
        color: #fff;
    }
    .sqaure-img img {
        width: 270px;
    }
}

@media (max-width: 480px) {
    .service-content h3 {
        font-size: 19px;
        font-weight: 100;
    }
    .mission h1 {
        font-size: 5rem;
        font-weight: 200;
        color: #fff;
    }

    .service-content p {
        font-size: 13px;
        font-weight: 200;
        letter-spacing: 0.5px;
        
    }
    .your_success p{
        text-align: justify;
    }
}


