
.section8{
    width: 100%;
    background-color: rgb(0, 0, 0);
    margin-top: 7%;
}
.banner8{
    top: 0;
    width: 100%;
    height: 90vh;
    background-image: url("../../../public/assets/images/cloudcomputing-cover.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    position: relative;
}


.main-content8{
  width: 100% !important;
  display: flex;
  align-items: center;
  padding: 0 50px;
}

.side-bar8{
  display:grid;
  place-items: center;

}
.line8{
  height: 200px;
  width: 6px;
  background: linear-gradient(to bottom, #000,#a50fed);
}
.circle8{
  width: 20px;
  height: 20px;
  background-color: #a50fed;
  border-radius: 50px;
  margin: 5px 0;
  display: grid;
  place-items: center;
}
.black-circle8{
  width: 15px;
  height: 15px;
  border: 3px solid #000;
  border-radius: 50px;

}
.line1-8{
  height: 200px;
  width: 6px;
  background: linear-gradient(to top, #000,#a50fed);
}


.content8 {
    text-align: left;
    margin-left: 5%;
    width: 100%;
    height: 40vh;
}
.content8 h1 {
  font-size: 2.5rem;
    font-weight: 500;
    text-align: left;
    font-family: 'BebasNeue-Regular';
 
    color: #ffffff;
}
.content8 h5 {
    font-weight: 500;
    color: #a50fed;
    font-family: 'Aileron-Bold';
}
.content8 p {
  color: #ffffff;
  max-width: 600px;
  font-family: 'Aileron-Light';
}
.list8{
  padding: 0 !important;
}
.list8 li{
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: left;
  color: #ffff;
  font-family: 'Aileron-Light';
}
.list8 li img{
  width: 20px;
}

/*form*/

.heroForm form{
  margin-left: auto;
}

.form8{
  background: linear-gradient(to top, #7d02d9 8%,#a50fed 50%  );
  width: 400px;
  margin-right: 6%;
  padding: 40px 20px;
  border-radius: 30px;

}
.form8 h6{
  text-align: center;
  color: #fff;
  padding: 8px 0;
  font-family: 'Aileron-Bold';

}
.input-box8{
  position: relative;
  width: 100%;
  margin: 10px 0;
  display: flex;
  align-items: center;
  border: 1px solid #b4b0b0;
}
.input-box8 img{
  margin-right: 10px;
}

.input-box8 input{
  width: 100%;
  padding: 8px 30px;
  border: none !important;
  background-color: transparent;
  color: #fff !important;
  font-size: 14px;
  outline: none;
  flex: 1;
}

.input-box8 span{
  position: absolute;
  left: 0;
  padding: 12px 30px;
  color: #ffffff;
  text-transform: uppercase;
  pointer-events: none;
  font-size: 13px;
  transition: 0.5s;
  font-family: 'Aileron-UltraLight';

}

.input-box8 input:valid ~ span,
.input-box8 input:focus ~ span
{
  color: #f8f8f8 !important;
  transform: translateX(20px) translatey(-13px);
  font-size: 0.6em;
  padding: 0 10px;
  font-weight: 500;
}
/* chrome*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button{
  -webkit-appearance: none;
}

/*firefox*/
/* input[type="number"]{
  -moz-appearance: textfield;
} */
.button-sec8{
  width: 100% !important;
  display: grid !important;
  place-items: center !important;
  top: 0 !important;
}
.submit8{
  width: 100%;
  background-color: transparent !important;
  border: 2px solid #fff !important;
  color: #ffffff !important;
  padding: 10px 50px !important;
  border-radius: 50px !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  margin-top: 0 !important;
  font-family: 'Aileron-Bold';

}
.submit8:hover{
  border: 1px solid #c277e4 !important;
}

.bar8{
  width: 600px;
  height: 8px;
  background-color: #a50fed;
  bottom: 0;
  position: absolute;
}

#phone-number{
  margin-left: 40px;
}

.flag8{
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-right: 1px solid #ffff;
}
.flag8 p{
  color: #fff;
  padding-top: 14px;
}
@media (max-width:999px){

}

@media screen and (max-width:1240px){
    .content8 {
        position: relative;
        margin-left: 8%;
        width: 100%;
        height: auto;
        margin-top: 6%;
    }
    .content8 h1 {
        font-size: 38px;
        font-weight: 500;
        text-align: left;
   
        line-height: 48px;
        color: #ffffff;
    }
}

@media (min-width:769px) and (max-width:1150px){
   
  .heroForm .form8{
    margin-left: -67px;
    margin-top: 35%;
    }
    .phoneicon{
      position: absolute;
      top: 0;
      right: -5px;
    }
}
@media screen and (max-width:825px){
    
.content8 {
    position: relative;
    margin-left: 8%;
    width: 100%;
    height: auto;
    margin-top: 10%;
  }
  
  .content8 h1 {
    font-size: 32px;
    font-weight: 500;
    text-align: left;
    /* font-family: 'Bebas Neue', sans-serif; */
    line-height: 45px;
    color: #ffffff;
  }
  
  .side-bar8{
  display:grid;
  place-items: center;
  margin-top: 10%;
  }
  

 
  .input-box8 input{
  width: 100%;
  padding: 8px 30px;
  border: none !important;
  background-color: transparent;
  color: #fff !important;
  outline: none;
  flex: 1;
  }
}

@media screen and (max-width: 768px) {
    .banner8{
        display: block;
        width: 100%;
        height: 100%;
        /* background-image: url(../images/cloudcomputing-cover.jpg); */
        background-position: 55% 20%;
        background-repeat: no-repeat;
        align-items: center;
        position: relative;
        padding-bottom: 28px;
      }

      .main-content8 {
        width: 100% !important;
        display: flex;
        align-items: center;
       padding: 0;
      }
      .content8 {
      position: relative;
      margin-left: 0;
      width: 100%;
      height: auto;
      margin-top: 115px;
      }
      .heroForm{
      padding: 25px;
      }
      .form8{
      width: 100%;
      margin-left: 0;
      }
}

@media (min-width:200px) and (max-width:580px){
    .content8 h1 {
        font-size: 37px;
        font-weight: 500;
        text-align: left;
  
        line-height: 41px;
        color: #ffffff;
    }

    .banner8{
        display: block;
        width: 100%;
        height: 100%;
        /* background-image: url(../images/cloudcomputing-cover.jpg); */
        background-position: 55% 20%;
        background-repeat: no-repeat;
        align-items: center;
        position: relative;
        padding-bottom: 28px;
      }
      .main-content8 {
        width: 100% !important;
        display: flex;
        align-items: center;
       padding: 0;
    }
    .content8 {
      position: relative;
      margin-left: 0;
      width: 100%;
      height: auto;
      margin-top: 115px;
  }
  .heroForm{
    padding: 25px;
  }
  .form8{
    width: 100%;
    margin-left: 0;
  }
}