
.zoho_solutions{
    width: 100%;
    text-align: center;
    /* margin-bottom: 30px; */
    display: grid;
    place-items: center;
    padding: 40px 20px;
  }
  .rowSolu1{
    width: 100%;
    max-width: 1200px;
  }
  .rowSolu1 h1{
    font-family: 'BebasNeue-Regular';
    color: #fff;
    font-size: 4rem;
  }
  .rowSolu1 p{
    text-align: center;
    width:100% !important;
    /* max-width: 60% !important;  */
    color: #ffffff;
    font-size: 14px;
    font-weight: 300;
    font-family: 'Aileron-UltraLight';
  }
  .solutionsCont{
    margin-top: 68px;
  }
  .colZoho{
    background: linear-gradient(to top,  #7d02d9 8%, #a50fed 50% );
    padding: 30px;
  }
  .colms2{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
  }
  .colm1{
    display: flex;
    align-items: center;
    text-align: left;
    border: 0.1px solid #b4b4b4;
    padding-left: 30px;
    gap: 10px;
    height: 170px;
  
  }
  .colm1 p{
    text-align: left;
    font-size: 15px;
  }
  .SL-ct{
    max-width: 350px;
   
  
  }
  .SL-ct h3{
    font-size: 28px;
    color: #fff;
    font-weight: 300;
    font-family: 'Aileron-UltraLight';
  }
  .zoho_img{
    width: 150px;
    background-color: rgb(255, 255, 255);
    height: 170px;
    display: grid;
    place-items: center;
  }
  .ZImg{
    width: 100px;
  }
  
  
  
  
  
  .how_We_Work{
    width: 100%;
    margin-bottom: 40px;
    background-color: #fff;
    position: relative;
    display: grid;
    place-items: center;
  }
  .Work_Section{
    width: 100% !important;
    padding: 40px 30px!important;
    color: #000000;
    margin-top: 15px !important;
    display: grid;
    place-items: center;
    text-align: center !important;
  }
  .Work_Section h1{ 
    font-family: 'BebasNeue-Regular' ;
    font-size: 3.5rem;

  }
  .Work_Section p{
  max-width: 1200px;
    font-weight: 200 !important;
    font-size: 15px;
    z-index: 9999;
    font-family: 'Aileron-UltraLight';
  }
  .triangle1{
    width: 0;
    height: 0;
    margin: 10px;
    border-bottom: 100px solid #ffffff;
    border-left: 80px solid transparent;
    border-right: 80px solid transparent;
    rotate: 180deg;
    position: absolute;
    bottom: -25%;
  }
  
  
  
  .process{
    width: 100%;
    display: grid;
    place-items: center;
    margin: 5% 0;
    border-bottom: 1px solid #797979;
    background-image: url('../../../../public/assets/images/process_arrow.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  
  .workingProcess_content{
    width: 100%;
    max-width: 1200px;
  
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    gap: 13%;
    flex-wrap: wrap;
  
    
  }
  .column4-1{
    display: grid;
    place-items: center;
    margin: 15px 0;
  }.IMG img{
    width: 70px;
  }
  .column4-1 p{
    text-align: center;
    color: #fff;
    font-size: 26px;
    padding-top: 10px;
    font-weight: 300;
    line-height: 30px;
    font-family: 'Aileron-UltraLight';
  }
  
  
  .container_last{
    width: 100%;
    display: grid;
    place-items: center;
    text-align: center;
    color: #fff;
  }
  .row_whyChoose{
    width: 100%;
    max-width: 1250px;
    display: grid;
    place-items: center;
  
  }
  .row_whyChoose h1{
    font-family: 'BebasNeue-Regular';
    font-size: 3.5rem;
  }
  .col_choose{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0;
    text-align: left;
    gap: 5%;
    
  
  }
  .parner_img{
    display: flex;
    align-items: end;
  } 
  .parner_img img{
    width: 400px;
  }
  .bar{
    width: 15px;
    height: 150px;
    background-color: #a50fed;
  }
  .listing_contents li {
    display: flex;
    gap: 10px;
    padding: 5px 0;
    font-family: 'Aileron-UltraLight';
  }
  .listing_contents li  img{
    width: 18px;
  }
  
  
  
  
  .contact-btn9{
    margin: 30px 0;
    display: grid;
    place-items: center;
  }
  .contact9{
   border-radius: 50px !important;
    background-color: transparent !important;
    border: 1px solid #a50fed !important;
    box-shadow: none !important;
    outline: none !important;
    padding: 8px 100px !important;
    font-size: 15px !important;
    color: #a50fed !important;
    font-family: 'Aileron-Bold';
  }
  .contact9:hover{
    border: 1px solid #bb55ea !important;
    font-weight: 500 !important;
  }

  @media screen and (max-width:1240px){
    
    .colms2{
      width: 100%;
      display: block !important;
      place-items: center !important;
      justify-content: center;
      margin:  -7px !important;
    }
    .colm1{
      width: 100% !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: left;
      border: 0.1px solid #b4b4b4;
      padding-left: 30px;
      /* gap: 10px; */
      height: 150px;
      margin: 18px 0;
    }
    .colm1 p{
      text-align: left;
      font-size: 14px;
     
    }
    .SL-ct{
      max-width: 80%;

    }
    .SL-ct h3{
      font-size: 28px;
      color: #fff;
      font-weight: 300;
    }
    .zoho_img{
      width: 150px;
      background-color: rgb(255, 255, 255);
      height: 150px;
      display: grid;
      place-items: center;
    }
    .ZImg{
      width: 100px;
    }

    .row_whyChoose{
      width: 100%;
      max-width: 1250px;
      display: grid;
      place-items: center;
    padding: 0 20px;
    }
    .col_choose{
      display: grid;
      place-items: center;
      margin: 50px 0;
      text-align: left;
      gap: 0;
    }
    .listing_contents  {
     margin-top: 10px;
    }
  
}

@media screen and (max-width:770px){
  .colm1{
    width: 100% !important;
    display: grid;
   place-items: center;
    /* justify-content: center; */
    text-align: left;
    border: 0.1px solid #b4b4b4;
    padding: 30px;
    /* gap: 10px; */
    height: auto;
    margin: 18px 0;
    text-align: center;
  }
  .colm1 p{
    text-align: center;
    font-size: 14px;
   
  }

}

@media (max-width:500px){
  .triangle1{
    width: 0;
    height: 0;
    margin: 10px;
    border-bottom: 100px solid #ffffff;
    border-left: 80px solid transparent;
    border-right: 80px solid transparent;
    rotate: 180deg;
    position: absolute;
    bottom: -15%;
  }


    .colm1 p {
        text-align: justify;

    }
 
    .contact9{
      border-radius: 50px !important;
       background-color: transparent !important;
       border: 1px solid #a50fed !important;
       box-shadow: none !important;
       outline: none !important;
       padding: 8px 100px !important;
       font-size: 15px !important;
     }

     .parner_img img{
      width: 100%;
    }

    #footer-logo5{
      width: 200px;
    }
    
}

@media screen and (max-width: 768px) {
  
.colZoho{
  display: grid !important;
  place-items: center;
  flex-wrap: wrap;
}
}







