
.section2{
    width: 100%;

    margin-top: 7%;
}
.banner2{
    top: 0;
    width: 100%;
    height: 40vh;
    background-image: url("../../../public/assets/images/career-cover.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #3e3e3e;
}

.content2 {
    text-align: center;
    margin-left: 8%;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40vh;
    /* margin-top: 6%; */
}
.content2 h1 {
    font-size: 3.5rem;
    font-weight: 500;
    text-align: left;
    font-family: 'BebasNeue-Regular';
    line-height: 58px;
    color: #ffffff;
}
.content2 h1 span{
    font-size: 2.5rem;
}

.bar2{
  width: 650px;
  height: 8px;
  background-color: #a50fed;
  bottom: 0;
  position: absolute;
}

@media (min-width:200px) and (max-width:999px){
     
    .content2 {
        position: relative;   
        text-align: center;
        margin-left: 8%;
        display: flex;
        align-items: center;
        width: 100%;
        height: 40vh;
        margin-top: 6%;
    }
    .content2 h1 {
        font-size: 3.5rem;
        font-weight: 500;
        text-align: left;
        /* font-family: 'Bebas Neue', sans-serif; */
        line-height: 58px;
        color: #ffffff;
    }

  
}

@media (max-width: 768px){
    .banner2 {
        top: 0;
        width: 100%;
        height: 40vh;
        /* background-image: url(http://localhost:3001/static/media/career-cover.76c646b….jpg); */
        background-position: right;
    }
}
@media (min-width:200px) and (max-width:580px){
    .content2 h1 {
        font-size: 28px;
        font-weight: 500;
        text-align: left;
        /* font-family: 'Bebas Neue', sans-serif; */
        line-height: 40px;
        color: #ffffff;
    }
    .content2 h1 span{
        font-size: 28px;
    }
    .banner2{
        top: 0;
        width: 100%;
        height: 40vh;
        background-image: url("../../../public/assets/images/career-cover.jpg");
        background-position: right;
        background-size: cover;
        background-repeat: no-repeat;
        display: block;
        align-items: center;
        position: relative;
    }
}