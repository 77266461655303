
.future-container{
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    display: grid;
    place-items: center;
  }
  .future-container h1{
    font-family: 'BebasNeue-Regular';
    color: #fff;
  }
  .future-container p{
    text-align: left;
    width:100% !important;
    max-width: 60% !important; 
    font-family: 'Aileron-UltraLight';
    color: white;
    font-weight: 200;
    font-size: 16px;
  }
  
  
  .future-content{
    width: 100%;
    max-width: 60% ;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: left;
    color: #fff;
  
  }
  .future-list li{
    padding: 5px 0;
    font-size: 18px;
    font-weight: 200;
    font-family: 'Aileron-UltraLight';
  
  }
  .future-list li img{
    width: 15px;
  }
  
  .services-container{
    width: 100% !important;
    max-width: 83% !important;
    padding: 0 0 0 30px !important;
    display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-wrap: wrap !important;
  }
  
  .best-ser{
  
  display: flex ;
  align-items: center !important;
  justify-content: center !important;
  flex-wrap: wrap !important;
  }
  
  .services6{
    width: 100% !important;
    display: flex !important;
    /* align-items: center !important; */
    gap: 15px !important;
    height: 160px !important;
    background-color: #000 !important;
    border: 1px solid #626269 !important;
    text-align: left;
    margin: 5px 0 !important;
  }
  .services-content h6{
    color: #fff;
    font-size: 18px;
    font-family: 'Aileron-Bold'
  }
  .services-content p{
    width: 100% !important;
    max-width: 100% !important;
    font-size: 16px;
    font-weight: 200;
    color: #fff;
    font-family: 'Aileron-UltraLight'
  }
  .services6 img{
    width: 60px;
  }
  /* .col6{
    background-color: #000 !important;
  } */
  
  .contact-btn6{
    margin: 30px 0;
    font-family: 'Aileron-Bold'
  }
  .contact6{
   border-radius: 50px !important;
    background-color: transparent !important;
    border: 1px solid #a50fed !important;
    box-shadow: none !important;
    outline: none !important;
    padding: 8px 80px !important;
    font-size: 15px !important;
    color: #a50fed;
  }
  .contact6:hover{
    border: 1px solid #bb55ea !important;
    font-weight: 500 !important;
    color: #a50fed;
  }

  @media screen and (max-width:999px){
        .best-ser {
    display: block;
  }
  }
  @media screen and (max-width:680px){
    .services6{
        width: 100% !important;
        display: grid !important;
        place-items: center !important;
        gap: 15px !important;
        height: auto !important;
        background-color: #000 !important;
        border: 1px solid #ccc !important;
        text-align: center;
      }
      .services-content p{
        width: 100% !important;
        max-width: 100% !important;
        font-size: 14px;
        color: #fff;
        text-align: center;
      }
  }

  @media screen and (max-width:576px){
    .future-container h1{
        font-family: 'Bebas Neue', sans-serif;
        color: #fff;
        font-size: 30px;
      }

      .future2 p {
        font-size: 15px;
      }
  }
  @media screen and (max-width:476px){
    .future-container h1{
        font-family: 'Bebas Neue', sans-serif;
        color: #fff;
        font-size: 25px;
      }

      .future-list li {
        padding: 5px 0;
        font-size: 16px;
    }

    .contact6 {
        padding: 7px 40px !important;
    }
    .services-conten p{
      font-size: 20px;
    }
  }


