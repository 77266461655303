
.section9{
    width: 100%;
    margin-top: 7%;
}
.banner9{
    top: 0;
    width: 100%;
    height: 40vh;
    background-image: url('../../../public/assets/images/about-covernew.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    align-items: center;
    /* background-color: #000013; */
    position: relative;
    border-bottom: 1px solid #3e3e3e;
}

.content9 {
    text-align: center;
    margin-left: 8%;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40vh;
    border-bottom: 1px solid #3e3e3e;
    /* margin-top: 6%; */
}
.content9 h1 {
    font-size: 3.5rem;
    font-weight: 500;
    text-align: left;
    font-family: 'BebasNeue-Regular';
    line-height: 58px;
    color: #ffffff;
}
.content9 h1 span{
    font-size: 2.5rem;
}

.bar9{
  width: 650px;
  height: 8px;
  background-color: #a50fed;
  bottom: 0;
  position: absolute;
}
@media screen and (max-width:1240px){
    
  .content9 h1 {
    font-size: 3rem;
    font-weight: 500;
    text-align: left;
    /* font-family: 'Bebas Neue', sans-serif; */
    line-height: 50px;
    color: #ffffff;
}
}

@media screen and (max-width: 768px) {

  .section9{
    width: 100%;
   
    margin-top: 4%;
}
  .content9 {
    border-bottom: 0px ;
  }
    .banner9{
        display: block;
        width: 100%;
        height: 100%;
        /* background-image: url(../images/about-cover.jpg); */
        background-position: right;
        background-repeat: no-repeat;
        align-items: center;
        position: relative;
        padding-bottom: 28px;
      }
    
      .content9 {
        text-align: center;
        margin-left: 8%;
        display: flex;
        align-items: center;
        width: 100%;
        height: 40vh;
        margin-top: 15%;
    }
    .content9 h1 {
      font-size: 2.8rem;
      font-weight: 500;
      text-align: left;
      /* font-family: 'Bebas Neue', sans-serif; */
      line-height: 50px;
      color: #ffffff;
    }
}

@media screen and (max-width: 457px) {
    .content9 h1 {
        font-size: 36.8px;
        font-weight: 500;
        text-align: left;
        /* font-family: 'Bebas Neue', sans-serif; */
        line-height: 42px;
        color: #ffffff;
      }
}